import React, { useState } from "react";
import { selectType } from "./BenchmarkUpload";
import { useSensitivityLevelCodes } from "../../api/benchmarking/BenchmarkingAPI";
import { jsonToSpreadsheet } from "../../utils/spreadsheets";
import {
  deleteBenchmarking,
  saveSensitivityInformationFlag,
} from "../../api/benchmarking-control/BenchmarkingControlAPI";
import { formatKey, removePropeties, deepCopy } from "./Benchmark.functions";

export function BenchmarkInputSingle(props) {
  const { value, onChange, placeholder, icon } = props;
  const [focus, setFocus] = useState(false);
  let iconType = "input-icon";
  if (focus || value !== "") iconType = "input-icon-focus";

  return (
    <div className="input-container">
      <div className={iconType}>{icon}</div>
      <div className="input-col">
        {placeholder}
        <div className="input-row">
          <input
            className="b-c-search-input"
            type="text"
            value={value}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            autoComplete="off"
          ></input>
        </div>
      </div>
    </div>
  );
}

export function BenchmarkSelectSingle(props) {
  const { value, values, onChange, placeholder, icon } = props;
  const [focus, setFocus] = useState(false);
  let iconType = "input-icon";
  if (focus || value !== "") iconType = "input-icon-focus";

  return (
    <div className="input-container">
      <div className={iconType}>{icon}</div>
      <div className="input-col">
        {placeholder}
        <div className="input-row">
          <select
            className="b-c-search-input"
            value={value}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
          >
            {selectOptions(values)}
          </select>
        </div>
      </div>
    </div>
  );
}

function selectOptions(values) {
  return values.map((item, i) => {
    return (
      <option key={i} value={item.value} className="option">
        {item.label}
      </option>
    );
  });
}

export function BenchmarkSelectSingleFilter(props) {
  const { value, values, onChange, placeholder, icon, filter } = props;
  const [focus, setFocus] = useState(false);
  let iconType = "input-icon";
  if (focus || value !== "") iconType = "input-icon-focus";

  return (
    <div className="input-container">
      <div className={iconType}>{icon}</div>
      <div className="input-col">
        {placeholder}
        <div className="input-row">
          <select
            className="b-c-search-input"
            value={value}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
          >
            {selectOptionsFilter(values, filter)}
          </select>
        </div>
      </div>
    </div>
  );
}

function selectOptionsFilter(values, filter) {
  return values.map((item, i) => {
    if (item.type === filter || item.type === "") {
      return (
        <option key={i} value={item.value} className="option">
          {item.label}
        </option>
      );
    } else {
      return null;
    }
  });
}

export function BenchmarkInputDouble(props) {
  const { value1, onChange1 } = props;
  const { value2, onChange2 } = props;
  const { placeholder, icon } = props;
  const [focus, setFocus] = useState(false);
  let iconType = "input-icon";
  if (focus || value1 !== "" || value2 !== "") iconType = "input-icon-focus";

  return (
    <div className="input-container">
      <div className={iconType}>{icon}</div>
      <div className="input-col">
        {placeholder}
        <div className="input-row">
          <input
            className="b-c-search-input-pair"
            type="text"
            value={value1}
            onChange={(e) => {
              onChange1(e.target.value);
            }}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            autoComplete="off"
          ></input>
          <input
            className="b-c-search-input-pair-second"
            type="text"
            value={value2}
            onChange={(e) => {
              onChange2(e.target.value);
            }}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            autoComplete="off"
          ></input>
        </div>
      </div>
    </div>
  );
}

export function DeleteBenchmark(props) {
  const { selectedBenchmarks, setBenchmarking } = props;
  let selection = selectedBenchmarks.map((b) => b.id);

  return (
    <div
      className="benchmark-control-delete-button"
      onClick={() => {
        const confirm = window.confirm(
          "Confirm delete " +
            selection.length +
            " benchmark project(s)? This cannot be undone."
        );
        if (confirm) {
          deleteBenchmarking(selection, setBenchmarking);
        }
      }}
    >
      <i className="far fa-trash-alt"></i>
    </div>
  );
}

export function FlagBenchmark(prop) {
  const { selectedBenchmarks } = prop;
  const { setBenchmarking } = prop;
  const { setShow } = prop;
  const [error, setError] = useState({});
  const [infoSensLevel, setInfoSensLevel] = useState("");
  const { sensitivityLevelCodes } = useSensitivityLevelCodes();
  const secLevelOptions = [{ label: "Select Case", value: "" }];
  sensitivityLevelCodes?.data?.forEach((c) => {
    secLevelOptions.push({ value: c.value, label: c.label });
  });
  let selection = selectedBenchmarks.map((b) => b.id);

  let data = {
    selection: selection,
    setModal: setShow,
    setBenchmarking: setBenchmarking,
    setError: setError,
    sensitivityFlag: infoSensLevel,
  };

  return (
    <div className="benchmark-upload">
      <div className="benchmark-upload-control-container">
        <div className="benchmark-upload-control">
          <div className="benchmark-upload-control-row">
            <div className="benchmark-upload-control-label">
              Level Of Security:
            </div>
            <select
              value={infoSensLevel}
              className="benchmark-upload-control-input"
              onChange={(e) => {
                setInfoSensLevel(e.target.value);
              }}
            >
              {selectType(secLevelOptions)}
            </select>
          </div>
        </div>
      </div>
      <div className="general-row-container">
        <Errors error={error} setError={setError} />
      </div>
      <div className="general-button-container">
        <input
          type="button"
          className="general-upload-button"
          onClick={() => {
            saveSensitivityInformationFlag(data);
          }}
          value="Save"
        />
      </div>
    </div>
  );
}

function generateHeaderRow(rowData) {
  const headerRow = [];
  Object.keys(rowData).forEach((key) => {
    let formattedKey = formatKey(key);
    headerRow.push(formattedKey);
  });
  return headerRow;
}

export function ExportBenchmark(props) {
  const { selectedBenchmarks } = props;
  const excelFileName = "benchmark";

  return (
    <button
      className="benchmark-control-export-button"
      onClick={() => {
        if (selectedBenchmarks && selectedBenchmarks.length > 0) {
          const confirm = window.confirm(
            "Confirm export " +
              selectedBenchmarks.length +
              " benchmark project(s)?"
          );
          if (confirm) {
            let transformedData = removePropeties(deepCopy(selectedBenchmarks));
            let headerRow = generateHeaderRow(transformedData[0]);
            jsonToSpreadsheet(excelFileName, transformedData, headerRow);
          }
        } else {
          window.alert("Please select a benchmark to export.");
        }
      }}
    >
      <i className="fa fa-download" aria-hidden="true"></i>
    </button>
  );
}

function Errors(prop) {
  const { error } = prop;
  return (
    <div className="account-edit-error-container">
      <div className={error.type}>
        <b>{error.text}</b>
      </div>
    </div>
  );
}
