import isEmpty from "../../validation/is-empty";
import { comma } from "../../helpers/common.functions";

export function filterBenchmarks(benchmarking, filter) {
  if (isEmpty(benchmarking)) {
    return [];
  }

  return benchmarking.filter((b) => {
    return benchmarkFilterCondition(b, filter);
  });
}

export function benchmarkFilterCondition(b, filter) {
  // Project Fields
  let number = "";
  if (!isEmpty(b.project_number)) {
    number = b.project_number.toString().toLowerCase();
  }
  let name = b.project_name ? b.project_name.toLowerCase() : "";
  let city = b.city ? b.city.toLowerCase() : "";
  let region = b.region ? b.region.toLowerCase() : "";
  let sector = b.sector ? b.sector.toLowerCase() : "";
  let date = Date.parse(b.base_date);

  // If there is an error with the date, set an arbitrary date so it will filter
  if (isNaN(date)) {
    date = Date.parse("1 Jan 2000");
  }

  let rate = b.construction_cost_rate ? b.construction_cost_rate : "";
  let cCost = b.construction_cost ? b.construction_cost : "";
  let pCost = b.project_cost ? b.project_cost : "";
  let metrics = b.metrics || [{ metric: "" }];

  // The Search Filter
  if (
    number.includes(filter.number) &&
    name.includes(filter.name) &&
    city.includes(filter.city) &&
    region.includes(filter.region) &&
    sector.includes(filter.sectorValue) &&
    date >= filter.minDate &&
    date <= filter.maxDate &&
    rate >= filter.minRateCost &&
    rate <= filter.maxRateCost &&
    cCost >= filter.minConstructionCost &&
    cCost <= filter.maxConstructionCost &&
    pCost >= filter.minProjectCost &&
    pCost <= filter.maxProjectCost &&
    metrics.find((m) => m.metric.includes(filter.metric))
  ) {
    return b;
  } else {
    return null;
  }
}

export function findDifference(array1, array2) {
  return array1.filter(
    ({ id: id1 }) => !array2.some(({ id: id2 }) => id2 === id1)
  );
}


export function formatValue(value) {
  if (typeof value === "object" || Array.isArray(value)) {
    return null; // Skip showing arrays
  }

  if (typeof value === "number") {
    value = comma(value);
  }

  return value;
}

export function formatDate(value) {
  const dateObject = new Date(value);
  const formattedDate = `${dateObject.getDate()} ${getMonthAbbreviation(dateObject)} ${dateObject.getFullYear()}`;

  return formattedDate;
}

function getMonthAbbreviation(date) {
  const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];
  return monthNames[date.getMonth()];
}

export function formatKey(key) {
  let formattedKey = key.replace(/_/g, ' ');
  switch (formattedKey) {
    case 'project name':
      formattedKey = 'Name';
      break;
    case 'standard':
      formattedKey = 'CBS';
      break;
    case 'construction cost rate':
      formattedKey = 'Construction Cost/ Local Region';
      break;
    case 'quality':
      formattedKey = 'Project Quality';
      break;
    case 'contactEmail':
      formattedKey = 'Contact Email';
      break;

    default:
      formattedKey = formattedKey
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
      break;
  }

  return formattedKey;
}

export function removePropeties(dataResponse) {
  let data = [];
  // Removing properties not required in export from the object.
  dataResponse.forEach(element => {
    delete element['id'];
    delete element['companyId'];
    delete element['source'];
    delete element['type'];
    delete element['project_name_short'];
    delete element['project_number'];
    delete element['exclusiveProjectId'];
    data.push(element);
  });
  return data;
}

export function deepCopy(object) {
  return JSON.parse(JSON.stringify(object));
}