import React from "react";
import PropTypes from "prop-types";

import { Form, FormError, SaveForm } from "./BenchmarkCreate.components";

/// props validation | SQ(javascript:S6774)
BenchmarkCreateConfigurationModal.propTypes = {
  forms: PropTypes.array,
};
///
export default function BenchmarkCreateConfigurationModal(props) {
  const { forms } = props;
  return (
    <div className="benchmark-create-configuration-modal">
      <div className="benchmark-create-configuration-modal-content-container">
        {forms.map((form) => {
          const { key } = form;
          return (
            <div
              key={key}
              className="benchmark-create-configuration-modal-content"
            >
              <Form {...form} />
            </div>
          );
        })}
      </div>
      <FormError {...props} />
      <SaveForm {...props} />
    </div>
  );
}
