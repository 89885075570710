import React from "react";
import PropTypes from "prop-types";

import ExportBenchmarkHeatMap from "../export/ExportBenchmarkHeatMap";
import { comma } from "../../helpers/common.functions";
import "./BenchmarkControlHeatmap.css";

import {
  columnsFromProjects,
  generateRows,
  addTotals,
  colourCells,
} from "./BenchmarkingHeatMap.functions";
import isEmpty from "../../validation/is-empty";
import { tranStr } from "../../utils/translation";

const AVERAGE_COLUMN = 2;

/// props validation | SQ(javascript:S6774)
BenchmarkingHeatMap.propTypes = {
  skipHighlight: PropTypes.bool,
  thisBenchmark: PropTypes.object,
  benchmarks: PropTypes.array,
  isElementalUnitRate: PropTypes.bool,
  selectedCostType: PropTypes.string,
  costTypeLabel: PropTypes.string,
  title: PropTypes.string,
  unitOfMeasure: PropTypes.string,
  currencySymbol: PropTypes.string,
  cbs: PropTypes.object,
};
///
export default function BenchmarkingHeatMap(props) {
  const { skipHighlight } = props;

  const { thisBenchmark, benchmarks } = props;
  const { isElementalUnitRate } = props;
  const { selectedCostType } = props;
  const { costTypeLabel } = props;
  const { title } = props;
  const { unitOfMeasure } = props;
  const { currencySymbol } = props;
  const { cbs } = props;

  let theBenchmarks = [thisBenchmark];
  const allBenchmarks = theBenchmarks.concat(benchmarks);
  // List the headings
  const headings = [
    { column_name: costTypeLabel + " " + tranStr("Cost Elements") },
    { column_name: tranStr("Unit") },
  ];

  // Add each Project's name as a column heading
  const columns = columnsFromProjects(headings, allBenchmarks);

  const categoryGroup = cbs?.data?.categoryGroups?.find(
    (g) => g.categoryName === "Cost Type"
  );

  // Unique list of ALL elements across BENCHMARK and LINKED BENCHMARKS
  const costTypeCodes = categoryGroup?.categoryItems?.find(
    (g) => g.description === selectedCostType
  );

  // Unique list of ALL elements across BENCHMARK and LINKED BENCHMARKS
  const allElements = cbs?.data?.elementCodes;

  const elements = allElements?.filter((e) =>
    costTypeCodes?.codes.includes(e.code)
  );

  if (isEmpty(elements)) {
    return null;
  }

  // Generate each row
  let rows = generateRows(
    elements,
    allBenchmarks,
    isElementalUnitRate,
    unitOfMeasure
  );

  rows = addTotals(rows, allBenchmarks, costTypeLabel, unitOfMeasure);

  // Colour the cells
  rows = colourCells(rows);

  const displayedCols = [];
  const displayedRows = [];

  columns.forEach((p, i) => {
    let headingType = "benchmark-element-heading";
    if (i === 1) {
      headingType = "benchmark-element-uom-heading";
    }
    if (i === 3 && !skipHighlight) {
      headingType = "benchmark-element-project-heading";
    }

    displayedCols.push(
      <th className={headingType} key={i}>
        {p.column_name}
      </th>
    );
  });
  rows.forEach((r, j) => {
    // Don't show Percentages
    if (r.unit === "%") {
      return null;
    }

    if (
      r.cells.every((c, i) => {
        // If column is - Benchmark 1 | Benchmark 2 | ...
        if (i > AVERAGE_COLUMN) {
          // Don't display the row if all of the Benchmarks are 0 or null
          return c.field === 0 || c.field === null;
          // If column is - Description | Unit | Project - return true anyway as we ignore them
        } else {
          return true;
        }
      })
    ) {
      return null;
    }

    const displayedRowsContent = [];

    r.cells.forEach((cell, i) => {
      if (i === r.cells.length - 1) {
        return;
      }
      displayedRowsContent.push(
        processCell(cell, i, rows.length - 1, j, currencySymbol)
      );
    });

    displayedRows.push(<tr key={j}>{displayedRowsContent}</tr>);
  });

  return (
    <div className="benchmark-element-flex">
      <div className="benchmark-element-table-box">
        <div className="benchmark-element-table-container">
          <table className="benchmark-element-table">
            <thead className="benchmark-element-head">
              <tr>{displayedCols}</tr>
            </thead>
            <tbody className="benchmark-body">{displayedRows}</tbody>
          </table>
        </div>
        <Legend />
      </div>
      <ExportBenchmarkHeatMap
        rows={rows}
        projectName={title}
        columns={columns}
      />
    </div>
  );
}

function Legend() {
  return (
    <div className="benchmark-element-legend">
      <div className="benchmark-element-legend-bar">
        <div className="benchmark-element-legend-low">
          <b>{tranStr("Very Low")}</b>
        </div>
        <div className="benchmark-element-legend-range-lightblue">
          <b>{tranStr("Low")}</b>
        </div>
        <div className="benchmark-element-legend-range-lightgreen">
          <b>{tranStr("Mid")}</b>
        </div>
        <div className="benchmark-element-legend-range">
          <b>{tranStr("Center")}</b>
        </div>
        <div className="benchmark-element-legend-range-lightgreen">
          <b>{tranStr("Mid")}</b>
        </div>
        <div className="benchmark-element-legend-range-lightred">
          <b>{tranStr("High")}</b>
        </div>
        <div className="benchmark-element-legend-high">
          <b>{tranStr("Very High")}</b>
        </div>
      </div>
      <div className="benchmark-element-legend-alone-element">
        <b>{tranStr("No data")}</b>
      </div>
    </div>
  );
}

function processCell(cell, i, lastRow, row, currency_symbol) {
  if (i === 0 && row !== lastRow) {
    return (
      <td
        className="benchmark-element-cell-description"
        key={i}
        style={{ backgroundColor: cell.colour }}
      >
        <div className="benchmark-cell-description-label">
          {comma(cell.field)}
        </div>
      </td>
    );
  } else if (i === 0 && row === lastRow) {
    return (
      <td
        className="benchmark-element-cell-description"
        key={i}
        style={{ backgroundColor: cell.colour }}
      >
        <div className="benchmark-cell-description-label">
          <b>{comma(cell.field)}</b>
        </div>
      </td>
    );
  } else if (i === 1) {
    return (
      <td
        className="benchmark-element-cell-unit"
        key={i}
        style={{ backgroundColor: cell.colour }}
      >
        <div className="benchmark-cell-unit-label">
          {comma(cell.field) || "m2"}
        </div>
      </td>
    );
  } else if (i === 3) {
    return (
      <td
        className="benchmark-element-cell-coloured"
        key={i}
        style={{ backgroundColor: cell.colour }}
      >
        <div className="benchmark-element-cell-container">
          <div className="benchmark-element-symbol">{currency_symbol}</div>
          <div className="benchmark-element-value">
            <b>{comma(cell.field)}</b>
          </div>
        </div>
      </td>
    );
  } else if (i === AVERAGE_COLUMN) {
    let averageValue = "";

    if (!isNaN(cell.field)) {
      averageValue = comma(cell.field);
    }

    return (
      <td
        className="benchmark-element-cell-average"
        key={i}
        style={{ backgroundColor: cell.colour }}
      >
        <div className="benchmark-element-cell-container">
          <div className="benchmark-element-symbol">{currency_symbol}</div>
          <div className="benchmark-element-value">
            <b>{averageValue}</b>
          </div>
        </div>
      </td>
    );
  } else {
    return (
      <td
        className="benchmark-element-cell-coloured"
        key={i}
        style={{ backgroundColor: cell.colour }}
      >
        <div className="benchmark-element-cell-container">
          <div className="benchmark-element-symbol">{currency_symbol}</div>
          <div className="benchmark-element-value">{comma(cell.field)}</div>
        </div>
      </td>
    );
  }
}
