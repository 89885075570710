import React from "react";
import PropTypes from "prop-types";

import { comma } from "../../helpers/common.functions";
import { Bar } from "react-chartjs-2";

/// props validation | SQ(javascript:S6774)
CostBenchmarking.propTypes = {
  caller: PropTypes.string,
  unitOfMeasure: PropTypes.string,
  title: PropTypes.string,
  benchmarks: PropTypes.array,
  thisBenchmark: PropTypes.object,
};
///
export default function CostBenchmarking(props) {
  const { caller } = props;

  const { unitOfMeasure, title } = props;
  const { benchmarks } = props;
  const { thisBenchmark } = props;

  let display = true;
  let unit = " / " + unitOfMeasure;

  if (!display) {
    return null;
  }

  const benchmarksTitles = [
    thisBenchmark.project_name,
    ...benchmarks.map((b) => b.project_name),
  ];

  if (caller === "COST_PLANNING") {
    benchmarksTitles[0] = title;
  }

  let heritage = "rgba(228, 97, 15, 1)";
  let aqua = "rgba(50, 98, 149, 1)";
  let collaboration1 = "rgba(85, 87, 90, 1)";
  let heritage2 = "rgba(228, 97, 15, 0.5)";
  let aqua2 = "rgba(50, 98, 149, 0.5)";
  let collaboration2 = "rgba(85, 87, 90, 0.7)";
  let collaboration3 = "rgba(85, 87, 90, 0.3)";

  const CHART_COLORS = [
    "",
    heritage,
    aqua,
    collaboration1,
    aqua2,
    heritage2,
    collaboration2,
    collaboration3,
  ];

  const data = generateCostCategoryData(benchmarks, thisBenchmark);
  const list = data?.map((d, index) => {
    return {
      label: d.label,
      backgroundColor: CHART_COLORS[index],
      data: d.data,
      stack: 4,
      barThickness: 15,
    };
  });

  const chartOptions = {
    maintainAspectRatio: false,
    indexAxis: "y",
    legend: {
      display: true,
      position: "bottom",
    },
    scales: {
      xAxes: [
        {
          stacked: true,
        },
      ],
      yAxes: [
        {
          ticks: {
            callback: function (tick, index, values) {
              return comma(tick) + unit;
            },
            stepSize: 1000,
            beginAtZero: true,
          },
          stacked: true,
        },
      ],
    },
    tooltips: {
      enabled: true,
      xPadding: 10,
      yPadding: 10,
      callbacks: {
        label: function (tooltipItem) {
          return comma(Math.round(tooltipItem.yLabel)) + unit;
        },
      },
    },
  };

  return (
    <div className="benchmarking-chart-box-costm2">
      <Title title={"Cost Benchmarking" + (title ? `- ${title}` : "")} />
      <div className="benchmarking-chart-container">
        <Bar
          data={{
            labels: benchmarksTitles,
            datasets: list,
          }}
          options={chartOptions}
        />
      </div>
    </div>
  );
}

function generateCostCategoryData(benchmarks, thisBenchmark) {
  const categoryGroup = thisBenchmark?.category_groups?.find(
    (g) => g.categoryName === "Element Categories"
  );

  const data = categoryGroup?.categoryItems?.map((category) => {
    return { label: category.field, data: [] };
  });
  data?.forEach((element) => {
    benchmarks.forEach((b) => {
      const categoryGroup = b?.category_groups?.find(
        (g) => g.categoryName === "Element Categories"
      );
      categoryGroup?.categoryItems?.forEach((c) => {
        if (c.field === element.label) {
          element.data.push(Math.round(c.value / b.local_region_area));
        }
      });
    });
  });

  data?.forEach((element) => {
    const categoryGroup = thisBenchmark?.category_groups?.find(
      (g) => g.categoryName === "Element Categories"
    );

    categoryGroup?.categoryItems?.forEach((c) => {
      if (c.field === element.label) {
        element.data.unshift(
          Math.round(c.value / thisBenchmark.local_region_area)
        );
      }
    });
  });

  return data;
}

/// props validation | SQ(javascript:S6774)
Title.propTypes = {
  title: PropTypes.any,
};
///
function Title(props) {
  const { title } = props;
  return (
    <h1
      className="display-4"
      style={{
        fontSize: "20px",
        marginBottom: "20px",
        marginTop: "10px",
        marginLeft: "10px",
      }}
    >
      {title}
    </h1>
  );
}
