import React, { useState } from "react";
import PropTypes from "prop-types";

import isEmpty from "../../validation/is-empty";
import { comma, FormatDate } from "../../helpers/common.functions";
import Modal from "../modal/Modal";
import BenchmarkPage from "../benchmarking/BenchmarkPage.components";

/// props validation | SQ(javascript:S6774)
ProjectRow.propTypes = {
  benchmark: PropTypes.object,
  isProject: PropTypes.bool,
  unitOfMeasure: PropTypes.string,
  country: PropTypes.string,
  currencySymbol: PropTypes.string,
  cbs: PropTypes.object,
};
///
export default function ProjectRow(props) {
  const { benchmark } = props;
  const { isProject } = props;
  const { unitOfMeasure } = props;
  const { country } = props;
  const { currencySymbol } = props;
  const { cbs } = props;

  const [modal, setModal] = useState(false);

  const [isProjectWarningHovered, setIsProjectWarningHovered] = useState(false);
  const [isLocationWarningHovered, setIsLocationWarningHovered] =
    useState(false);

  let rowType = "bdt-row";
  if (isProject) {
    rowType = "bdt-project-row";
  }

  if (isEmpty(benchmark)) {
    return null;
  }
  let unit = " " + unitOfMeasure;

  let projectwarning = "";
  let locationwarning = "";
  if (!props.isProject && benchmark.country !== country) {
    projectwarning = (
      <span
        className={`project-warning-icon ${
          isProjectWarningHovered ? "show-tooltip" : ""
        }`}
        onMouseEnter={() => setIsProjectWarningHovered(true)}
        onMouseLeave={() => setIsProjectWarningHovered(false)}
      >
        <i className="fas fa-exclamation-triangle" />
        <span className="tooltip">
          Comparison projects have not been adjusted for location, since
          location factors are not applied across countries.
        </span>
      </span>
    );
  }

  if (!props.isProject && !isEmpty(benchmark.locationWarning)) {
    locationwarning = (
      <span
        className={`location-warning-icon ${
          isLocationWarningHovered ? "show-tooltip" : ""
        }`}
        onMouseEnter={() => setIsLocationWarningHovered(true)}
        onMouseLeave={() => setIsLocationWarningHovered(false)}
      >
        <i className="fas fa-exclamation-triangle" />
        <span className="tooltip">Locality factor cannot be found.</span>
      </span>
    );
  }

  return (
    <div
      className={rowType}
      onClick={() => {
        // dev only setModal(!modal);
      }}
    >
      <div className="bdt-row-name-cell">
        {bold(isProject, benchmark.project_name)} {projectwarning}{" "}
        {locationwarning}
      </div>
      <div className="bdt-base-date">
        {bold(isProject, FormatDate(benchmark.base_date))}
      </div>
      <div className="bdt-gfa">
        {bold(isProject, comma(benchmark.local_region_area) + " " + unit)}
      </div>
      <div className="bdt-cost">
        {bold(
          isProject,
          currencySymbol + comma(Math.round(benchmark.construction_cost_rate))
        )}
      </div>
      <div className="bdt-cost">
        {bold(
          isProject,
          currencySymbol + comma(benchmark.escalated_construction_cost_rate)
        )}
      </div>
      <div className="bdt-procurement">
        {bold(isProject, benchmark.procurement_method)}
      </div>
      <div className="bdt-type">{bold(isProject, benchmark.project_type)}</div>
      <div className="bdt-quality">{bold(isProject, benchmark.quality)}</div>
      <div className="bdt-sector">{bold(isProject, benchmark.sector)}</div>
      <Modal
        // Modal Props
        title={"Benchmark"}
        Component={BenchmarkPage}
        modal={modal}
        setModal={setModal}
        // Component Props
        benchmark={benchmark}
        cbs={cbs}
      />
    </div>
  );
}

function bold(isProject, value) {
  if (isProject) {
    return <b>{value}</b>;
  } else {
    return value;
  }
}
