import React, { useState, useEffect } from "react";
import isEmpty from "../../validation/is-empty";

import "./ProjectControl.css";
import "./ProjectControlEditStages.css";
import "./CostPlanStages.css";

import { DisplayError } from "./ProjectControl";
import { submitStages } from "./CostPlanStages.functions";
import { translate } from "../../utils/translation";
import { useStages } from "../../api/estimates/EstimateAPI";

export default function CostPlanStages(props) {
  const { projectID } = props;
  const { project } = props;
  const { CPs } = props;
  const { setModal } = props;

  const { stages } = useStages(projectID);

  const [newStages, setStages] = useState([]);

  useEffect(() => {
    if (!isEmpty(stages)) {
      setStages(stages.data);
    }
  }, [stages]);

  const [error, setError] = useState({});

  const data = {
    newStages: newStages,
    projectID: projectID,
    CPs: CPs,
    project: project,
    setModal: setModal,
    setError: setError,
  };

  return (
    <div className="edit-stages-container">
      <div className="flex-container-stages">
        <Headings newStages={newStages} />
        {generateFields(newStages)}
      </div>
      <div className="general-row-container">
        <button
          className="general-upload-button"
          id={"cost-plan-stages-add-stage-button"}
          onClick={addStage}
        >
          {translate("Add Stage")}
        </button>
      </div>
      <div className="general-row-container">
        <DisplayError error={error} setError={setError} />
      </div>

      <div className="general-button-container">
        <div
          className="general-upload-button"
          onClick={async () => {
            submitStages(data);
          }}
        >
          {translate("Save")}
        </div>
      </div>
    </div>
  );

  function generateFields(nstgs) {
    return nstgs.map((s, i) => {
      return (
        <div key={s.id} className="input-stages-row">
          <input
            className="input-stages-code"
            value={s.stage}
            onChange={(e) => {
              updateStage(e.target.value, i, nstgs);
            }}
          />
          <input
            className="input-stages"
            value={s.stage_name}
            onChange={(e) => {
              updateName(e.target.value, i, nstgs);
            }}
          />
          <input
            className="input-stages-code"
            value={s.stage_code}
            onChange={(e) => {
              updateCode(e.target.value, i, nstgs);
            }}
          />

          <div
            className="stages-delete"
            onClick={() => deleteStage(s.id, nstgs)}
          >
            {translate("Delete")}
          </div>
        </div>
      );
    });
  }

  function updateName(value, i, nstgs) {
    let tempStages = nstgs;
    tempStages[i].stage_name = value;
    setStages(tempStages.map((s) => s));
  }

  function updateCode(value, i, nstgs) {
    if (value.length <= 4) {
      let tempStages = nstgs;
      tempStages[i].stage_code = value;
      setStages(tempStages.map((s) => s));
    }
  }

  function updateStage(value, i, nstgs) {
    if (value <= 10) {
      let tempStages = nstgs;
      tempStages[i].stage = value;
      setStages(tempStages.map((s) => s));
    }
  }

  function deleteStage(id, nstgs) {
    let temp = nstgs;
    temp.splice(nstgs.map((x) => x.id).indexOf(id), 1);
    setStages(temp.map((x) => x));
  }

  function addStage() {
    let temp = newStages;

    // Find the highest number
    let maxId = Math.max(...newStages.map((i) => i.id));

    if (temp.length < 6) {
      temp.push({
        id: maxId + 1,
        project_id: projectID,
        stage: "",
        stage_name: "",
        stage_code: "",
      });
      setStages(temp.map((x) => x));
    }
  }
}

function Headings(props) {
  const { newStages } = props;

  if (isEmpty(newStages)) {
    return null;
  }

  return (
    <div className="stage-field-titles">
      <div className="input-stages-code">
        <b>{translate("Number")}</b>
      </div>
      <div className="input-stages">
        <b>{translate("Stage Name")}</b>
      </div>
      <div className="input-stages-code">
        <b>{translate("Code")}</b>
      </div>
    </div>
  );
}
