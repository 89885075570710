import { useState } from "react";
import PropTypes from "prop-types";

import isEmpty from "../../validation/is-empty";
import { translate, tranStr } from "../../utils/translation";
import {
  deepClone,
  dateDDMMYYYYToISODateString,
} from "../../helpers/common.functions";

import {
  useLocations,
  useLocationFactors,
} from "../../api/admin/RegionManagementAPI";
import { useBenchmarking } from "../../api/benchmarking-control/BenchmarkingControlAPI";

import {
  sectorList,
  subsectorList,
  qualityList,
  typeList,
  currencyList,
} from "../project-create/CreateProject.functions";

import { CONTROL_TRIGGER_TYPES } from "../master/MasterPage";

import { MASTER_BENCHMARKING } from "./BenchmarkCreate.strings";

import BenchmarkCreateConfigurationModal from "./BenchmarkCreateConfigurationModal";
import BenchmarkCreateSelectionModal from "./BenchmarkCreateSelectionModal";
import BenchmarkCreateAnalysisGate from "./BenchmarkCreateAnalysisGate";

/// props validation | SQ(javascript:S6774)
useBenchmarkCreateConfigurationControls.propTypes = {
  user: PropTypes.object,
};
///
export function useBenchmarkCreateConfigurationControls({ user }) {
  const [showConfigure, setShowConfigure] = useState(false);
  const [showSelectProjects, setShowSelectProjects] = useState(false);

  const [sector, setSector] = useState("");
  const [subsector, setSubsector] = useState("");
  const [quality, setQuality] = useState("");
  const [projectType, setProjectType] = useState("");
  const [currency, setCurrency] = useState("");
  const [unitOfMeasure, setUnitOfMeasure] = useState("m²");

  const currencySymbol = "";

  const { locations } = useLocations(true);
  const { locationFactors } = useLocationFactors();

  const [region, setRegion] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [baseYear, setBaseYear] = useState("");

  const { benchmarking: benchmarks, setBenchmarking: setBenchmarks } =
    useBenchmarking(user);

  const [benchmarkSelectionFilters, setBenchmarkSelectionFilters] = useState(
    {}
  );
  const [benchmarkSelection, setBenchmarkSelection] = useState([]);
  const [selectedBenchmarkIds, setSelectedBenchmarkIds] = useState([]);

  const [error, setError] = useState(null);

  function clearError() {
    setError(<></>);
  }

  // handle onSave on BenchmarkCreateConfigurationModal
  // see BenchmarkCreateConfigurationModal > SaveForm > onClick
  function onSaveBenchmarkCreateConfigurationModal(e, forms) {
    const success = validateConfiguration(forms);

    return success;
  }

  function validateConfiguration(forms) {
    clearError();

    const { isValid, emptyFields } = isFormsValid(forms);

    if (!isValid) {
      setError(
        <>
          <div>{MASTER_BENCHMARKING.WARNINGS.REQUIRED_FIELDS_MISSING}</div>
          <div>{emptyFields.join(", ")}</div>
        </>
      );

      return false;
    }

    return true;
  }

  const isConfigurationComplete = () =>
    isFormsValid(controls?.find((c) => c.key === "configure")?.forms).isValid;

  function isFormsValid(forms) {
    if (!forms) {
      return { isValid: false };
    }

    const emptyFields = [];

    for (const form of forms) {
      for (const formControl of form.formControls) {
        const { name, isRequired, value } = formControl;
        if (isRequired && isEmpty(value)) {
          emptyFields.push(name);
        }
      }
    }

    return { isValid: isEmpty(emptyFields), emptyFields };
  }

  // handle onSave on BenchmarkCreateSelectionModal
  // see BenchmarkCreateSelectionModal > SaveForm > onClick
  function onSaveBenchmarkCreateSelectionModal(e, forms) {
    const success = validateBenchmarkSelection();

    if (success) {
      refreshSelectedBenchmarkIds();
    }

    return success;
  }

  // atleast 2 benchmarks need to be selected for this to be true
  const isBenchmarkSelectionComplete = () => benchmarkSelection.length > 1;

  function validateBenchmarkSelection() {
    clearError();

    if (isEmpty(benchmarkSelection) || !isBenchmarkSelectionComplete()) {
      setError(
        <div>{MASTER_BENCHMARKING.WARNINGS.REQUIRED_BENCHMARKS_MISSING}</div>
      );
      return false;
    }

    return true;
  }

  // refresh selectedBenchmarkIds when [benchmarkSelection] changes
  async function refreshSelectedBenchmarkIds() {
    const benchmarkIds = benchmarkSelection.map((s) => ({
      benchmark_id: s.id,
    }));

    setSelectedBenchmarkIds(benchmarkIds);
  }

  const isGenerateReady = () =>
    isConfigurationComplete() && isBenchmarkSelectionComplete();

  const controls = [
    {
      key: "configure",
      label: translate("Configure"),
      className: "",
      type: CONTROL_TRIGGER_TYPES.OPEN_MODAL,
      show: showConfigure,
      setShow: setShowConfigure,
      ModalComponent: BenchmarkCreateConfigurationModal,
      onBeforeOpen: clearError,
      // modal component props
      // props to Form within BenchmarkCreateConfigurationModal
      forms: [
        {
          key: "report_details",
          label: translate("Report Details"),
          icon: "far fa-file-alt",
          // form fields
          formControls: [
            {
              name: "Project Sector",
              isRequired: false,
              component: "Select",
              key: "sector",
              label: translate("Project Sector"),
              placeholder: tranStr("Project Sector"),
              value: sector,
              options: sectorList(),
              onChange: setSector,
            },
            {
              name: "Project Subsector",
              isRequired: false,
              component: "SelectFilter",
              key: "sub_sector",
              label: translate("Project Subsector"),
              placeholder: tranStr("Project Subsector"),
              value: subsector,
              options: subsectorList(),
              onChange: setSubsector,
              filter: sector,
            },
            {
              name: "Project Quality",
              isRequired: false,
              component: "Select",
              key: "quality",
              label: translate("Project Quality"),
              placeholder: tranStr("Project Quality"),
              value: quality,
              options: qualityList(),
              onChange: setQuality,
            },
            {
              name: "Project Type",
              isRequired: false,
              component: "Select",
              key: "type",
              label: translate("Project Type"),
              placeholder: tranStr("Project Type"),
              value: projectType,
              options: typeList(),
              onChange: setProjectType,
            },
            {
              name: "Currency",
              isRequired: false,
              component: "Select",
              key: "currency",
              label: translate("Currency"),
              placeholder: tranStr("Currency"),
              value: currency,
              options: currencyList(),
              onChange: setCurrency,
            },
            {
              name: "Displayed Unit",
              isRequired: false,
              component: "Input",
              key: "unit_of_measure",
              label: translate("Displayed Unit"),
              placeholder: tranStr("Displayed Unit"),
              value: unitOfMeasure,
              set: setUnitOfMeasure,
              pattern: "",
            },
          ],
        },
        {
          key: "define_parameters",
          label: translate("Define Parameters"),
          icon: "fas fa-tasks",
          // form fields
          formControls: [
            {
              name: "Region",
              isRequired: true,
              component: "RegionSelect",
              key: "region",
              label: translate("Region"),
              value: region,
              set: setRegion,
              options: locations.data,
              classType: "create-project-location-select",
              setCountry: setCountry,
              setCity: setCity,
            },
            {
              name: "Country",
              isRequired: true,
              component: "CountrySelect",
              key: "country",
              label: translate("Country"),
              value: country,
              set: setCountry,
              options: locations.data,
              classType: "create-project-location-select",
              region: region,
              setCity: setCity,
            },
            {
              name: "City",
              isRequired: true,
              component: "CitySelect",
              key: "city",
              label: translate("City"),
              value: city,
              set: setCity,
              options: locations.data,
              classType: "create-project-location-select",
              country: country,
            },
            {
              name: "Escalation Year",
              isRequired: false,
              component: "Input",
              key: "base_date",
              label: translate("Escalation Year"),
              value: baseYear,
              set: setBaseYear,
              placeholder: "YYYY",
              pattern: "[1900-2999]{4}",
              type: "numberonly",
              minlength: "4",
              maxlength: "4",
            },
          ],
        },
        {
          key: "select_metrics",
          label: translate("Select Metrics"),
          icon: "fas fa-ruler",
          // form fields
          formControls: [
            {
              name: "Metrics",
              isRequired: false,
              component: "Text",
              key: "text_default_metrics",
              value: (
                <>
                  <p>The following metrics are selected as default:</p>
                  <ul className="text-left">
                    <li>Total Number of Floors</li>
                    <li>Local Region Area</li>
                    <li>Total Construction Cost</li>
                    <li>Total Project Cost</li>
                    <li>Construction Cost per GIA</li>
                    <li>Total Building Services Cost per GIA</li>
                  </ul>
                </>
              ),
            },
          ],
        },
      ],
      // props to SaveForm within BenchmarkCreateConfigurationModal
      onSave: onSaveBenchmarkCreateConfigurationModal,
      error,
    },
    {
      key: "select_projects",
      label: translate("Select Projects"),
      className: "",
      type: CONTROL_TRIGGER_TYPES.OPEN_MODAL,
      show: showSelectProjects,
      setShow: setShowSelectProjects,
      ModalComponent: BenchmarkCreateSelectionModal,
      onBeforeOpen: clearError,
      // modal component props
      benchmarks,
      setBenchmarks,
      benchmarkSelectionFilters,
      setBenchmarkSelectionFilters,
      benchmarkSelection,
      setBenchmarkSelection,
      onSave: onSaveBenchmarkCreateSelectionModal,
      error,
    },
    {
      key: "generate",
      label: translate("Generate"),
      get className() {
        return isGenerateReady() ? "action" : "action-unavailable";
      },
      type: CONTROL_TRIGGER_TYPES.LOAD_PAGE,
      PageComponent: BenchmarkCreateAnalysisGate,
      // component props
      // props to BenchmarkCreateAnalysisGate
      get configurationComplete() {
        return isConfigurationComplete();
      },
      get benchmarkSelectionComplete() {
        return isBenchmarkSelectionComplete();
      },
      // props to BenchmarkCreateAnalysis
      title: "",
      sector,
      subsector,
      quality,
      projectType,
      currency,
      unitOfMeasure,
      currencySymbol,
      region,
      country,
      city,
      LFI: locationFactors?.data,
      baseDate: getDate({ year: baseYear }),
      selectedBenchmarkIds,
      benchmarkSelectionFilters,
      // props to BenchmarkCreateAnalysisDisclaimer
      disclaimerDisplay: {
        fields: [
          ["Project Function", subsector],
          ["Project Type", projectType],
          ["Quality Level", quality],
          ["Location", city],
          ["Escalation Year", baseYear],
          ["Currency", currency],
        ],
        fallbackValue: "-",
      },
    },
  ];

  return controls;
}

export function prepareOnBenchmarkAnonymityToggle({
  refBenchmarks,
  setRefBenchmarks,
  restBenchmarks,
  setRestBenchmarks,
}) {
  return function onBenchmarkAnonymityToggle(props) {
    // these props are being fed from BenchmarkAnonymity > input > onChange
    const { comparisonId, isAnonymous } = props;

    anonymizeBenchmark({
      benchmarks: refBenchmarks,
      setBenchmarks: setRefBenchmarks,
      comparisonId,
      isAnonymous,
      serialNumberStart: 1,
    });

    anonymizeBenchmark({
      benchmarks: restBenchmarks,
      setBenchmarks: setRestBenchmarks,
      comparisonId,
      isAnonymous,
      serialNumberStart: 2,
    });
  };
}

function anonymizeBenchmark({
  benchmarks,
  setBenchmarks,
  comparisonId,
  isAnonymous,
  serialNumberStart,
}) {
  const benchmarks_clone = deepClone(benchmarks);

  benchmarks_clone.forEach((benchmark, index) => {
    if (benchmark.id === comparisonId) {
      // anonymize benchmark name
      if (isAnonymous) {
        //preserve
        benchmark.project_name_original = benchmark.project_name;
        benchmark.project_name_short_original = benchmark.project_name_short;
        // apply
        const anonymizedName = `Benchmark ${index + serialNumberStart}`;
        benchmark.project_name = benchmark.project_name_short = anonymizedName;
        benchmark.isAnonymous = true;
      }
      // revert benchmark name
      else {
        // restore
        benchmark.project_name = benchmark.project_name_original;
        benchmark.project_name_short = benchmark.project_name_short_original;
        benchmark.isAnonymous = false;
      }
    }
  });

  setBenchmarks(benchmarks_clone);
}

export function linkBenchmarks({ thisBenchmark, benchmarks }) {
  if (thisBenchmark) {
    thisBenchmark.linked_benchmarks = benchmarks;
    thisBenchmark.linkedBenchmarksIds = benchmarks.map((b) => ({
      id: "",
      benchmarkId: b.id,
      isAnonymous: !!b.isAnonymous,
    }));
  }
}

export function getDate({ year }) {
  return year && dateDDMMYYYYToISODateString(`01/01/${year}`);
}
