import isEmpty from "../validation/is-empty";

// Project Dashboard
export function calculateEscalationShallow(TPI, benchmarks, futureYear) {
  // Escalate High level benchmark costs
  benchmarks?.forEach((b) => {
    let rate = b.construction_cost_rate;
    let escalated_rate = escalateBenchmark(TPI, null, rate, b, futureYear);

    b.escalated_construction_cost_rate = escalated_rate;
    b.displayed_construction_cost_rate = escalated_rate;
  });
}

export function calculateEscalationBenchmarkingDashboard(
  TPI,
  LFI,
  thisBenchmark,
  linkedBenchmarks,
  futureYear,
  projectCity,
  is_locality_factor_active,
  locality_factor
) {
  ////////////////
  // High Level Costs
  ///////////////

  // Escalate Costs for this Benchmark
  thisBenchmark.escalated_construction_cost_rate = escalateBenchmark(
    TPI,
    LFI,
    thisBenchmark.construction_cost_rate,
    thisBenchmark,
    futureYear,
    projectCity,
    is_locality_factor_active,
    locality_factor
  );
  // Escalate Costs for Comparison Benchmark
  linkedBenchmarks.forEach((b) => {
    b.escalated_construction_cost_rate = escalateBenchmark(
      TPI,
      LFI,
      b.construction_cost_rate,
      b,
      futureYear,
      projectCity,
      is_locality_factor_active,
      locality_factor
    );
  });

  ///////////////////
  // Row Level Costs
  ///////////////////
  thisBenchmark.rows.forEach((row) => {
    // Cost / m2 GFA
    row.escalated_local_region_rate = escalateBenchmark(
      TPI,
      LFI,
      row.local_region_rate,
      thisBenchmark,
      futureYear,
      projectCity,
      is_locality_factor_active,
      locality_factor
    );
    // Elemental Unit Rate
    row.escalated_rate = escalateBenchmark(
      TPI,
      LFI,
      row.rate,
      thisBenchmark,
      futureYear,
      projectCity,
      is_locality_factor_active,
      locality_factor
    );
  });

  linkedBenchmarks.forEach((b) => {
    b.rows.forEach((row) => {
      row.escalated_local_region_rate = escalateBenchmark(
        TPI,
        LFI,
        row.local_region_rate,
        b,
        futureYear,
        projectCity,
        is_locality_factor_active,
        locality_factor
      );
      // Elemental Unit Rate
      row.escalated_rate = escalateBenchmark(
        TPI,
        LFI,
        row.rate,
        b,
        futureYear,
        projectCity,
        is_locality_factor_active,
        locality_factor
      );
    });
  });
}

export function toggleEscalationBenchmarkingDashboard(
  isEscalated,
  thisBenchmark,
  linkedBenchmarks
) {
  ///////////////////
  //Display Costs
  ///////////////////

  // Determine Display Cost
  if (isEscalated) {
    // High Level Costs
    thisBenchmark.displayed_construction_cost_rate =
      thisBenchmark.escalated_construction_cost_rate;
    linkedBenchmarks.forEach((b) => {
      b.displayed_construction_cost_rate = b.escalated_construction_cost_rate;
    });

    // Row Costs
    thisBenchmark.rows.forEach((row) => {
      row.displayed_local_region_rate = row.escalated_local_region_rate;
      row.displayed_rate = row.escalated_rate;
    });
    linkedBenchmarks.forEach((b) => {
      b.rows.forEach((row) => {
        row.displayed_local_region_rate = row.escalated_local_region_rate;
        row.displayed_rate = row.escalated_rate;
      });
    });
  } else {
    // High Level Costs
    thisBenchmark.displayed_construction_cost_rate =
      thisBenchmark.construction_cost_rate;
    linkedBenchmarks.forEach((b) => {
      b.displayed_construction_cost_rate = b.construction_cost_rate;
    });

    // Row Costs
    thisBenchmark.rows.forEach((row) => {
      row.displayed_local_region_rate = row.local_region_rate;
      row.displayed_rate = row.rate;
    });
    linkedBenchmarks.forEach((b) => {
      b.rows.forEach((row) => {
        row.displayed_local_region_rate = row.local_region_rate;
        row.displayed_rate = row.rate;
      });
    });
  }
}

// Escalate Costs to the Base Date of the Estimate
export function escalateBenchmark(
  TPI,
  LFI,
  rate,
  benchmark,
  futureYear,
  projectCity,
  is_locality_factor_active,
  locality_factor
) {
  let currentIndex;
  let futureIndex;
  let currentYear;
  let percentage;
  let escalatedRate = rate;
  let benchmarkCity = benchmark.city;

  if (!isEmpty(TPI)) {
    // If a tpi_city override exists use instead
    if (benchmark.tpi_city) {
      benchmarkCity = benchmark.tpi_city;
    }

    const date = benchmark.base_date;

    currentYear = parseInt(
      new Date(date).toLocaleString("default", {
        year: "numeric",
      })
    );

    TPI.raw.forEach((row) => {
      if (row.year === currentYear && row.location === benchmarkCity) {
        currentIndex = row.value;
      }

      if (row.year === futureYear && row.location === benchmarkCity) {
        futureIndex = row.value;
      }
    });

    percentage = Math.round((futureIndex / currentIndex) * 100 - 100);
    escalatedRate = Math.round(rate + (rate * percentage) / 100);

    if (isNaN(escalatedRate)) {
      escalatedRate = rate;
    }
  }

  //Add location factor to the escalation
  let locationEscalatedRate = escalateLocationBenchmark(
    LFI,
    escalatedRate,
    benchmark,
    projectCity,
    is_locality_factor_active,
    locality_factor
  );

  return locationEscalatedRate;
}

// Escalate Costs based on the locality factors
export function escalateLocationBenchmark(
  LFI,
  rate,
  benchmark,
  projectCity,
  is_locality_factor_active,
  locality_factor
) {
  let escalatedRate = rate;
  if (isEmpty(LFI)) {
    return rate;
  }

  let benchmark_location = LFI.filter((x) => x.city === benchmark.city)[0];
  let project_location = LFI.filter((x) => x.city === projectCity)[0];

  //To use locality factor override already set in CostPlan, following if statement has to be true
  if (
    is_locality_factor_active &&
    !isEmpty(locality_factor) &&
    !isEmpty(benchmark_location) &&
    locality_factor !== 0 &&
    !isEmpty(benchmark_location.factor) &&
    isEmpty(benchmark.project_id)
  ) {
    escalatedRate = Math.round(
      (locality_factor / benchmark_location.factor) * rate
    ); //Override locality factor with value already set by user in Costplan
  } else {
    // use global locality factor
    if (
      !isEmpty(benchmark_location) &&
      !isEmpty(benchmark_location.factor) &&
      !isEmpty(project_location.factor) &&
      benchmark_location.factor !== 0
    ) {
      escalatedRate = Math.round(
        (project_location.factor / benchmark_location.factor) * rate
      );
    }

    //when locality factor is not found a warning will be displayed in the front-end
    if (benchmark_location && isEmpty(benchmark_location.factor)) {
      benchmark.locationWarning = true;
    }
  }

  if (isNaN(escalatedRate)) {
    return rate;
  }

  return escalatedRate;
}
