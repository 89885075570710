import { detectDeployment } from "./Utilities";

export function sharedAPIs() {
  const config = {
    serviceAPI: true,
    localService: "https://dev.cost-clarity.com/apis/sharedservice/v1.0",
    deployedService: "/apis/sharedservice/v1.0",
  };

  // Local dev https://localhost:44320/v1.0
  // Deployed dev https://dev.cost-clarity.com/apis/sharedservice/v1.0

  const D = detectDeployment(config);

  return {
    // Company APIs
    get_companies: `${D}/companies/get_companies`, // Updated
    get_companies_and_employee_ids: `${D}/companies/get_companies_and_employee_ids`, // NEW
    get_companies_by_ids: `${D}/companies/get_companies_by_ids`, // NEW
    delete_company: `${D}/companies/delete_company`, // OK
    create_company: `${D}/companies/create_company`, // OK
    set_employees: `${D}/companies/set_employees`, // OK
    get_employees: `${D}/companies/get_employees`, // Updated
    edit_company: `${D}/companies/edit_company`, // OK
    get_companies_by_email_domain: `${D}/companies/get_companies_by_email_domain`, // Updated
    add_single_employee: `${D}/companies/add_single_employee`, // NEW
    get_company_ids_by_employee_ids: `${D}/companies/get_company_ids_by_employee_ids`, // NEW

    //Locality factors APIs
    save_location_factors: `${D}/locality_factor/save_location_factors`,
    get_location_factors: `${D}/locality_factor/get_location_factors`,

    //Market insights
    upload_insight: `${D}/market_insights/upload_insight`,
    get_insights: `${D}/market_insights/get_insights`,
    update_insight: `${D}/market_insights/update_insight`,
    upload_article: `${D}/market_insights/upload_article`,
    update_article: `${D}/market_insights/update_article`,
    delete_article: `${D}/market_insights/delete_article`,
    delete_category: `${D}/market_insights/delete_category`,
    get_insights_read: `${D}/market_insights/get_insights_read`,
    upsert_insights_read: `${D}/market_insights/upsert_insights_read`,

    //Locations
    save_locations: `${D}/locations/update`,
    get_locations: `${D}/locations/get`,
    //TPI endpoints
    upload_tpi: `${D}/tpi/upload_tpi`,
    get_tpi: `${D}/tpi/get_tpi`,
    delete_tpi: `${D}/tpi/delete_tpi`,
  };
}
