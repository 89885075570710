import { useState, useEffect } from "react";
import isEmpty from "../../validation/is-empty";
import { authHeader } from "../../_helpers/auth-header";
import { sharedAPIs } from "../api-endpoints/SharedServiceEndpoints";

// Get Tender Price Index
export async function getTPI(region, country, isGlobal) {
  const url = sharedAPIs().get_tpi;

  const payload = {
    region: region,
    country: country,
  };

  const config = {
    method: "POST",
    body: JSON.stringify(payload),
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };
  let erMessage = "Get TPI Error";
  let response = await fetch(url, config);
  if (response.ok) {
    response = await response.json();
  } else {
    response = [];
    console.log(erMessage);
  }
  return response;
}

export function useTPI(active, region, country, isGlobal) {
  const [loading, setLoading] = useState(true);
  const [tpi, setTpi] = useState({});
  useEffect(() => {
    if (active && !isEmpty(region) && !isEmpty(country)) {
      setLoading(true);
      getTPI(region, country, isGlobal)
        .then((tpi) => {
          setTpi(tpi);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }, [active, region, country, isGlobal]);

  return {
    TPILoading: loading,
    TPI: tpi,
    setTPI: setTpi,
    error: null,
  };
}
