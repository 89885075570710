export function ExpandableRow(props) {
  const { headings } = props;
  const { values } = props;

  return (
    <div className="pctm-container">
      <div className="pctm-row-head">
        {headings.map((h) => {
          return (
            <div className="pctm-value-cell-head">
              <b>{h}</b>
            </div>
          );
        })}
      </div>

      <div className="pctm-row">
        {values.map((v) => {
          return <div className="pctm-value-cell">{v}</div>;
        })}
      </div>
    </div>
  );
}

export function OneValueRow(props) {
  const { heading1 } = props;
  const { value1 } = props;

  return (
    <div className="pctm-container-horizontal">
      <div className="pctm-row-head-text">
        <div className="pctm-value-head-text">
          <b>{heading1}</b>
        </div>
      </div>
      <div className="pctm-row">
        <div className="pctm-value-cell-text">{value1}</div>
      </div>
    </div>
  );
}

export function DocumentLink(props) {
  const { R } = props;
  // Check if a URL has https://
  const regexp = /^(?:[a-z]+:)?\/\//i;

  if (R.document_link.length === 0) {
    return null;
  }

  let URL = "";
  if (regexp.test(R.document_link)) {
    URL = R.document_link;
  } else {
    URL = "//" + R.document_link;
  }

  return (
    <div className="pctm-container-horizontal">
      <div className="pctm-row-head-text">
        <div className="pctm-value-head-text">
          <b>Document Link</b>
        </div>
      </div>
      <div className="pctm-row">
        <a
          className="pctm-value-cell-text"
          href={URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          Link to External Site
        </a>
      </div>
    </div>
  );
}

export function Description(props) {
  const { R } = props;

  return (
    <div className="pctm-row">
      <div className="display-4">
        <b>{R.description}</b>
      </div>
    </div>
  );
}

export function MultiValueRow(props) {
  const { itemData } = props;
  const { keys } = props;

  const row = [];
  Object.entries(itemData).forEach(([key, value]) => {
    if (!keys.includes(key)) return;
    row.push(
      <div
        className={
          key === "metric"
            ? "pctm-metrix-cell pctm-value-cell-tex"
            : "pctm-metrix-cell pctm-value-cell-number"
        }
      >
        {value}
      </div>
    );
  });

  return (
    <div className="pctm-container-horizontal pctm-container-matrix-horizontal">
      <div className="pctm-row pctm-matrix-row">{row}</div>
    </div>
  );
}
