import { getBenchmarking } from "../../api/benchmarking-control/BenchmarkingControlAPI";
import { authHeader } from "../../_helpers/auth-header";
import { bmAPIs } from "../../api/api-endpoints/BenchmarkingEndpoints";
import { tranStr } from "../../utils/translation";
import { AutoCloseModal } from "../../components/modal/Modal";

export async function onSubmit(data) {
  if (data.file.length < 1) {
    return data.setError({
      text: "Please attach a file",
      type: "feedback-error",
    });
  }

  if (data.CBS === "") {
    return data.setError({
      text: "Please select CBS",
      type: "feedback-error",
    });
  }

  if (data.sourceType === "") {
    return data.setError({
      text: "Please select Source Type",
      type: "feedback-error",
    });
  }

  data.setError({
    text: "Uploading...",
    type: "feedback-success",
  });

  const payload = {
    CBS: data.CBS,
    sourceType: data.sourceType,
    measurementSystem: data.measurementSystem,
    informationSensitivityLevel: data.informationSensitivityLevel,
    companyId: data.companyId,
  };

  const formData = new FormData();
  const field = JSON.stringify(payload);
  formData.append("field", field);

  for (let i = 0; i < data.file.length; i++) {
    formData.append("file" + i, data.file[i]);
  }

  const url = bmAPIs().upload_benchmark;

  const config = {
    method: "POST",
    body: formData,
    headers: authHeader({
      authJson: false,
      authForm: true,
      guestJson: false,
    }),
  };

  let response = await fetch(url, config);

  if (response.ok) {
    response = await response.json();
    // Update the state
    data.setBenchmarking(await getBenchmarking());
    data.setMessages(response);
    data.setError({
      text: tranStr("Upload Complete"),
      type: "feedback-success",
    });
    AutoCloseModal(data.setModal);
  } else {
    console.log("Error in Upload");
    data.setError({
      text: tranStr("Upload Failed"),
      type: "feedback-error",
    });
  }
}
