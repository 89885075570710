import React from "react";
import PropTypes from "prop-types";

import Accordion from "../accordion/Accordion";
import BenchmarkSection from "./BenchmarkSection";
import ModalBenchmarkingSelection from "../benchmarking-project-selection/ModalBenchmarkingSelection";
import isEmpty from "../../validation/is-empty";
import EstimatesSubtotal from "../estimates/EstimatesSubtotal";
import EstimatesTotal from "../estimates/EstimatesTotal";
import { translate, tranStr } from "../../utils/translation";
import "./BenchmarkingProject.css";

import Fab from "@mui/material/Fab";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CustomWidthTooltip from "../muiControls/muiToolTip";
import BenchmarkingLimitationsData from "./BenchmarkingLimitationsData";

import { saveBenchmarkComparisons } from "../../api/estimates/EstimateBenchmarkAPI";
import {
  useProjectSettings,
  onSettingsChange,
} from "../../api/projects/ProjectSettingsAPI";

/// props validation | SQ(javascript:S6774)
BenchmarkingProject.propTypes = {
  user: PropTypes.object,
  CP: PropTypes.object,
  CPs: PropTypes.any,
  project: PropTypes.any,
  TPI: PropTypes.any,
  LFI: PropTypes.any,
  accordionState: PropTypes.any,
  benchmarking: PropTypes.array,
  setBenchmarking: PropTypes.func,
  estimateBenchComments: PropTypes.array,
  setEstimateBenchComments: PropTypes.func,
};
///
export default function BenchmarkingProject(props) {
  // Props
  const { user } = props;
  const { CP } = props;
  const { CPs } = props;
  const { project } = props;
  const { TPI, LFI } = props;
  const { accordionState } = props;

  // All Benchmarking
  const { benchmarking, setBenchmarking } = props;

  // Estimate Benchmark Commentary
  const { estimateBenchComments, setEstimateBenchComments } = props;

  // No Data
  if (isEmpty(CP)) {
    return (
      <div>
        {translate(
          "Cost Plan Benchmarking is available after a Cost Plan has been uploaded"
        )}
      </div>
    );
  }

  const display = [];
  // Estimates Included in the Total
  CP.estimates.forEach((e, i) => {
    if (e.is_included) {
      display.push(
        <div key={i}>
          <Benchmark
            e={e}
            user={user}
            CPs={CPs}
            CP={CP}
            project={project}
            TPI={TPI}
            LFI={LFI}
            benchmarking={benchmarking}
            setBenchmarking={setBenchmarking}
            estimateBenchComments={estimateBenchComments}
            setEstimateBenchComments={setEstimateBenchComments}
            accordionState={accordionState}
          />
          <EstimatesSubtotal
            project={project}
            estimates={CP.estimates}
            estimateID={e.id}
            subtotal_below={e.subtotal_below}
            subtotal_name={e.subtotal_name}
            subtotal_gfa_override={e.subtotal_gfa_override}
          />
        </div>
      );
    }
  });
  // The Total
  display.push(
    <EstimatesTotal project={project} CP={CP} key={CP.estimates.length} />
  );

  // Estimates Excluded from the Total
  CP.estimates.forEach((e, i) => {
    if (!e.is_included) {
      display.push(
        <div key={i}>
          <Benchmark
            e={e}
            user={user}
            CPs={CPs}
            CP={CP}
            project={project}
            TPI={TPI}
            LFI={LFI}
            benchmarking={benchmarking}
            setBenchmarking={setBenchmarking}
            estimateBenchComments={estimateBenchComments}
            setEstimateBenchComments={setEstimateBenchComments}
            accordionState={accordionState}
          />
          <EstimatesSubtotal
            project={project}
            estimates={CP.estimates}
            estimateID={e.id}
            subtotal_below={e.subtotal_below}
            subtotal_name={e.subtotal_name}
            subtotal_gfa_override={e.subtotal_gfa_override}
          />
        </div>
      );
    }
  });

  // For each benchmark we want to create an Accordion
  return <div className="estimate-benchmarking">{display}</div>;
}

/// props validation | SQ(javascript:S6774)
Benchmark.propTypes = {
  user: PropTypes.object,
  CP: PropTypes.object,
  CPs: PropTypes.any,
  project: PropTypes.object,
  TPI: PropTypes.any,
  LFI: PropTypes.any,
  e: PropTypes.object,
  accordionState: PropTypes.any,
};
///
function Benchmark(props) {
  // Props
  const { user } = props;
  const { CP } = props;
  const { CPs } = props;
  const { project } = props;
  const { TPI, LFI } = props;
  const { e } = props;
  const { accordionState } = props;

  // All Benchmarking
  const { benchmarking, setBenchmarking } = props;

  // extract relevant fields from project
  const {
    title,
    unit_of_measure: unitOfMeasure,
    currency_symbol: currencySymbol,
    city,
    country,
    region,
  } = project;

  // extract relevant fields from CP
  const { is_locality_factor_active, locality_factor } = CP;

  // We want to extract the benchmarks for the given project
  let uom = unitOfMeasure;

  //Extract locality factors for the given project properties
  let location_factors = null;
  if (!isEmpty(LFI)) {
    location_factors = LFI.filter(
      (x) => x.region === region && x.country === country
    );
  }

  let benchmarkName = "";
  let benchmarkRate = "";
  let benchmarkTotal = "";
  let linked_benchmarks = [];
  let benchmarkArea = "";
  const thisBenchmark = e.benchmark;

  if (!isEmpty(thisBenchmark)) {
    benchmarkName =
      thisBenchmark.project_name +
      " - " +
      tranStr("Benchmarking (Construction Costs)");

    benchmarkRate = thisBenchmark.construction_cost_rate;
    benchmarkArea = thisBenchmark.local_region_area;
    benchmarkTotal = Math.round(thisBenchmark.construction_cost);
    linked_benchmarks = thisBenchmark.linked_benchmarks;
  }

  const onBenchmarkAnonymityToggle = prepareOnBenchmarkAnonymityToggle({
    CPs,
    project,
  });

  const { settings } = useProjectSettings(project.id);
  const onSettingsToggle = prepareOnSettingsToggle({ project });

  return (
    <div className="benchmarking-flex-box">
      <Accordion
        label={benchmarkName}
        local_region_area={benchmarkArea}
        unit={uom}
        rate={benchmarkRate}
        total={benchmarkTotal}
        // State
        accordionState={accordionState}
        accordionSection={"BENCHMARKING"}
        estimateID={e.id}
      >
        <BenchmarkSection
          caller={"COST_PLANNING"}
          title={title}
          unitOfMeasure={unitOfMeasure}
          currencySymbol={currencySymbol}
          city={city}
          country={country}
          is_locality_factor_active={is_locality_factor_active}
          locality_factor={locality_factor}
          thisBenchmark={thisBenchmark}
          benchmarks={linked_benchmarks}
          TPI={TPI}
          LFI={location_factors}
          onBenchmarkAnonymityToggle={onBenchmarkAnonymityToggle}
          settings={settings}
          onSettingsToggle={onSettingsToggle}
        />
      </Accordion>
      <ModalBenchmarkingSelection
        user={user}
        projectID={project.id}
        estimate={e}
        estimateID={e.id}
        CPs={CPs}
        CP={CP}
        benchmarkName={benchmarkName}
        benchmarking={benchmarking}
        setBenchmarking={setBenchmarking}
        benchmarkingForBenchmark={linked_benchmarks}
        project={project}
      />
      <div>
        <BenchMarkingLimitations />
      </div>
    </div>
  );
}

function prepareOnBenchmarkAnonymityToggle({ CPs, project }) {
  return function onBenchmarkAnonymityToggle(props) {
    saveBenchmarkComparisons({
      CPs,
      project,
      ...props,
    });
  };
}

function prepareOnSettingsToggle({ project }) {
  return function onSettingsToggle(props) {
    onSettingsChange({ project, ...props });
  };
}

BenchMarkingLimitations.propTypes = {
  icon: PropTypes.string
};
export function BenchMarkingLimitations(props) {
  const {icon} = props
  return (
    <CustomWidthTooltip title={<BenchmarkingLimitationsData />}>
      <Fab
        sx={{
          position: "fixed",
          top: 157,
          marginLeft: "15px",
          color: "white",
          height: "37px",
          width: "37px",
          bgcolor: "var(--collaboration1)",
          "&:hover": {
            bgcolor: "var(--heritage)",
          },
        }}
      >
        {icon === "disclaimer"?
        < InfoOutlinedIcon  sx={{ height: "52px", width: "52px" }} />
        :
        <HelpOutlineIcon sx={{ height: "52px", width: "52px" }} />
        }
        
      </Fab>
    </CustomWidthTooltip>
  );
}
