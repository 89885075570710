import React, {
  cloneElement,
  useState,
  useMemo,
  createContext,
  useContext,
} from "react";
import PropTypes from "prop-types";

import Modal from "../modal/Modal";

import "./MasterPage.css";

export const MasterPageRootContext = createContext();

export default function MasterPage(props) {
  const [page, setPage] = useState(null);

  const MasterPageRootContextProviderValue = useMemo(
    function () {
      return {
        page,
        setPage,
      };
    },
    [page, setPage]
  );

  return (
    <MasterPageRootContext.Provider value={MasterPageRootContextProviderValue}>
      <MasterPageBase {...props} />
    </MasterPageRootContext.Provider>
  );
}

function MasterPageBase(props) {
  return (
    <div className="page-background">
      <LeftNavPane {...props} />
      <div className="page-content-container">
        <div className="page-content">
          <Page {...props} />
        </div>
      </div>
    </div>
  );
}

/// props validation | SQ(javascript:S6774)
LeftNavPane.propTypes = {
  title: PropTypes.string.isRequired,
  controls: PropTypes.array,
};
///
function LeftNavPane(props) {
  const { title } = props;
  const { controls } = props;

  return (
    <div className="left-nav-pane-backer">
      <div className="left-nav-pane">
        <div className="left-nav-pane-content">
          <LeftNavPaneTitle title={title} />
          <LeftNavPaneControlPanel controls={controls} />
        </div>
      </div>
    </div>
  );
}

/// props validation | SQ(javascript:S6774)
LeftNavPaneTitle.propTypes = {
  title: PropTypes.string.isRequired,
};
///
function LeftNavPaneTitle(props) {
  const { title } = props;

  return (
    <div className="left-nav-pane-title">
      <h1 className="display-4">{title}</h1>
    </div>
  );
}

/// props validation | SQ(javascript:S6774)
Page.propTypes = {
  children: PropTypes.element.isRequired,
};
///
function Page(props) {
  const context = useContext(MasterPageRootContext);
  const { page } = context;

  const { children } = props;

  if (page?.PageComponent) {
    const { PageComponent } = page;
    return <PageComponent {...page.props} />;
  }

  /* see https://stackoverflow.com/a/35102287/979621 */

  return <>{cloneElement(children, { ...props })}</>;
}

/// props validation | SQ(javascript:S6774)
LeftNavPaneControlPanel.propTypes = {
  controls: PropTypes.array,
};
///
function LeftNavPaneControlPanel(props) {
  const { controls } = props;

  return (
    <div className="project-control-panel">
      {controls?.map((item) => (
        <ControlTrigger key={item.key} id={item.key} {...item} />
      ))}
    </div>
  );
}

export const CONTROL_TRIGGER_TYPES = {
  OPEN_MENU: "OPEN_MENU",
  OPEN_MODAL: "OPEN_MODAL",
  LOAD_PAGE: "LOAD_PAGE",
};

/// props validation | SQ(javascript:S6774)
ControlTrigger.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.object.isRequired,
  className: PropTypes.string,
  type: PropTypes.string,
  items: PropTypes.array,
  show: PropTypes.bool,
  setShow: PropTypes.func,
  ModalComponent: PropTypes.func,
  PageComponent: PropTypes.func,
  onBeforeOpen: PropTypes.func,
};
///
function ControlTrigger(props) {
  const context = useContext(MasterPageRootContext);
  const { setPage } = context;

  const { id, label, className, type } = props;
  const { items } = props;
  const { show, setShow } = props;
  const { ModalComponent, PageComponent } = props;

  const { onBeforeOpen } = props;

  let buttonClassName = show ? "drawer-button-clicked" : "drawer-button";

  return (
    <div className="project-control-drawer">
      <button
        id={id}
        className={`${buttonClassName} ${className}`}
        onClick={() => {
          run(onBeforeOpen);

          if (type === CONTROL_TRIGGER_TYPES.LOAD_PAGE) {
            setPage({ PageComponent, props });
          } else {
            setShow?.(!show);
          }
        }}
      >
        {label}
      </button>
      {type === CONTROL_TRIGGER_TYPES.OPEN_MENU && show && (
        <ControlDrawer title={label} items={items} />
      )}
      {type === CONTROL_TRIGGER_TYPES.OPEN_MODAL && show && (
        <Modal
          Component={ModalComponent}
          modal={show}
          setModal={setShow}
          title={label}
          {...props}
        />
      )}
    </div>
  );
}

/// props validation | SQ(javascript:S6774)
ControlDrawer.propTypes = {
  title: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
};
///
function ControlDrawer(props) {
  const { title, items } = props;

  return (
    <div className="project-control-drawer-content">
      <div className="project-control-panel-controls">
        <h1 className="display-4">{title}</h1>
        {items.map((item) => (
          <ControlDrawerItem key={item.key} id={item.key} {...item} />
        ))}
      </div>
    </div>
  );
}

/// props validation | SQ(javascript:S6774)
ControlDrawerItem.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.object.isRequired,
  icon: PropTypes.string.isRequired,
  ModalComponent: PropTypes.func,
};
///
function ControlDrawerItem(props) {
  const { id, label, icon } = props;
  const { ModalComponent } = props;

  const [modal, setModal] = useState(false);

  return (
    <div className="project-control-panel-button-container">
      <button
        id={id}
        className="project-control-panel-button"
        onClick={() => setModal(true)}
      >
        <i className={icon}></i>
      </button>
      <h1 className="display-4">{label}</h1>
      {ModalComponent && (
        <Modal
          Component={ModalComponent}
          modal={modal}
          setModal={setModal}
          title={label}
          {...props}
        />
      )}
    </div>
  );
}

function run(func, args) {
  if (typeof func === "function") {
    (async () => {
      await func(args);
    })();
  }
}
