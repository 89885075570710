import React, { useState } from "react";
import { translate, tranStr } from "../../utils/translation";

// Components
import Table from "../table/Table";
import Modal from "../modal/Modal";
import PostContractCriticalSelection from "../post-contract-table-critical-selection/PostContractCriticalSelection";
import PostContractVariationModal from "../post-contract-table-modals/PostContractVariationModal";

// Visualisations
import PostContractTableContainer from "../post-contract-tables/PostContractTableContainer";
import CostReportVariationStatus from "../post-contract-variations/CostReportVariationStatus";
import PDPCDonutChangeTypeSummary from "../post-contract-cost-report/PDPCDonutChangeTypeSummary";

// Functions
import { variationColumns } from "../post-contract-tables/PostContractTable.functions";
import isEmpty from "../../validation/is-empty";

// API
import { pcAPIs } from "../../api/api-endpoints/PostContractEndpoints";

// Styles
import "./PostContractVariations.css";

export default function PostContractVariations(props) {
  const { postContract } = props;
  const { variations } = props;

  const [selectedRow, setSelectedRow] = useState({});
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);

  // No data available so return null
  if (isEmpty(variations.variations)) {
    return null;
  }

  // Visualisations
  const visuals = [
    <CostReportVariationStatus
      title={translate("Variation Status")}
      variations={variations.variations}
      CR={variations.cost_report}
    />,
    <PDPCDonutChangeTypeSummary
      title={translate("Change Type Summary")}
      variations={variations.variations}
    />,
  ];

  return (
    <div>
      <PostContractTableContainer Components={visuals} />
      <Table
        title={tranStr("Variations")}
        tableArray={variations.variations}
        columns={variationColumns()}
        tableSize={15}
        // Row Selection
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        // Modal Control
        setModal={setModal1}
      />
      <Modal
        // Modal Props
        title={translate("Variations")}
        Component={PostContractVariationModal}
        modal={modal1}
        setModal={setModal1}
        columns={variationColumns()}
        // Component Props
        selectedRow={selectedRow}
      />
      <Modal
        // Modal Props4
        title={"Select Critical"}
        Component={PostContractCriticalSelection}
        modal={modal2}
        setModal={setModal2}
        // Component Props
        setShow={setModal2}
        data={variations.variations}
        label={"Select Critical"}
        postContract={postContract}
        url={pcAPIs().save_critical_variations}
      />
      <button
        className="general-upload-button"
        onClick={() => {
          setModal2(true);
        }}
      >
        {"Select Critical"}
      </button>
    </div>
  );
}
