import { b2cPoliciesDev, b2cPoliciesPrd } from "./policies";

/**
 * Config object to be passed to MSAL on creation.
 * For a full list of msal.js configuration parameters,
 * visit https://azuread.github.io/microsoft-authentication-library-for-js/docs/msal/modules/_configuration_.html
 * */

// Arcadis Dev B2C
export function msalConfig() {
  const b2cPolicies = detectDeployment();

  return {
    auth: {
      clientId: b2cPolicies.clientId,
      authority: b2cPolicies.authorities.signUpSignIn.authority,
      knownAuthorities: [b2cPolicies.authorities.knownAuthorities],
      validateAuthority: false,
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: true,
    },
  };
}

// Options
export const options = {
  //loginType: LoginType.Redirect,
  tokenRefreshUri: window.location.origin,
};
/**
 * Scopes you enter here will be consented once you authenticate. For a full list of available authentication parameters,
 * visit https://azuread.github.io/microsoft-authentication-library-for-js/docs/msal/modules/_authenticationparameters_.html
 */
export const loginRequest = {
  scopes: ["openid", "profile", "email"],
};

// The current application coordinates were pre-registered in a B2C tenant.
export const apiConfig = {
  b2cScopes: [],
  webApi: "",
};

// Add here scopes for access token to be used at the API endpoints.
export const tokenRequest = {
  scopes: apiConfig.b2cScopes, // e.g. ["https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read"]
};

export function detectDeployment() {
  // Current Host
  const host = window.location.hostname;

  // Hosts
  const local = b2cPoliciesDev;
  const dev = b2cPoliciesDev;
  const tst = b2cPoliciesPrd;
  const prd = b2cPoliciesPrd;

  // Local
  if (host.includes("localhost")) {
    return local;
  }

  // Dev Deployment
  if (host.includes("dev")) {
    return dev;
  }

  // Test Deployment
  if (host.includes("test")) {
    return tst;
  }

  // Production Deployment
  if (host.includes("www")) {
    return prd;
  }
}
