import { tranStr } from "../../utils/translation";

export const MASTER_BENCHMARKING = {
  KEYS: {
    DISCLAIMER_LOCAL_STORAGE_KEY: "benchmarking-disclaimer-user-acceptance",
  },
  WARNINGS: {
    DISCLAIMER_NOT_ACCEPTED: tranStr("Please acknowledge the disclaimer"),
    REQUIRED_FIELDS_MISSING: `${tranStr("These fields are required")}:`,
    REQUIRED_BENCHMARKS_MISSING: tranStr("Please select atleast 2 projects"),
    CONFIGURATION_INCOMPLETE: tranStr("Project configuration incomplete"),
    BENCHMARK_SELECTION_INCOMPLETE: tranStr("Project selection incomplete"),
  },
};
