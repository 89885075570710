import React from "react";

import "./Cashflow.css";
import CashflowGraph from "./CashflowGraph";
import CashflowTable from "./CashflowTable";
import CashflowControlPanel from "./Cashflow.components";

import { connect } from "react-redux";
import isEmpty from "../../validation/is-empty";

function Cashflow(props) {
  // User
  const { user } = props.auth;

  // Data
  const { project, CP, CPs, cashflowBasis } = props;
  const { cashflowData } = props;
  const { totalCost } = props;
  const { estimateID } = props;

  if (isEmpty(CP)) {
    return null;
  }

  const { cashflow } = CP;

  return (
    <div className="cashflow-content">
      <div className="cashflow-column">
        <CashflowControlPanel
          cashflow={cashflow}
          cashflowData={cashflowData}
          totalCost={totalCost}
          user={user}
          estimateID={estimateID}
          project={project}
          CPs={CPs}
          cashflowBasis={cashflowBasis}
        />
        <CashflowTable data={cashflowData?.CashflowRows} total={totalCost} />
      </div>
      <div className="cashflow-column">
        <CashflowGraph data={cashflowData?.CashflowRows} />
      </div>
    </div>
  );
}

export default connect((state) => ({
  auth: state.auth,
}))(Cashflow);
