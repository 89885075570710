import React, { useState } from "react";
import { connect } from "react-redux";
import Modal from "../modal/Modal";
import { hasRoles } from "../../utils/roles";

import "./BenchmarkUploadModal.css";
import { FlagBenchmark } from "./Benchmark.components";

function ModalBenchmarkInfoFlag(prop) {
  const { selectedBenchmarks, setBenchmarking } = prop;
  const { user } = prop.auth;

  const [modal, setModal] = useState(false);

  if (!hasRoles(user.roles, ["CostManager"])) {
    return null;
  }

  return (
    <div>
      <Modal
        // Modal Props
        title={"Information Flagging"}
        Component={FlagBenchmark}
        modal={modal}
        setModal={setModal}
        // Component Props
        setBenchmarking={setBenchmarking}
        selectedBenchmarks={selectedBenchmarks}
        setShow={setModal}
      />
      <div
      aria-hidden="true"
        className="benchmark-control-upload-button"
        onClick={() => {
          setModal(true);
        }}
      >
        <i className="fas fa-flag" />
      </div>
    </div>
  );
}

export default connect((state) => ({
  auth: state.auth,
}))(ModalBenchmarkInfoFlag);
