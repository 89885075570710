// React
import React from "react";
import isEmpty from "../../validation/is-empty";
import { translate } from "../../utils/translation";
import { commaWithNaN } from "../../helpers/common.functions";

import "./CostReportTable.css";
import { Link } from "react-router-dom";

import {
  TABLEGROUPHEADINGS,
  TABLEHEADINGS,
  CONSTRUCTIONCOSTROWS,
  CLIENTCOSTROWS,
  CONTINGENCYROWS,
  getColumnTotal,
  COLADJUSTEDBUDGET,
  COLREVISEDCONTRACTVALUE,
  COLANTICIPATEDVARIATIONS,
  COLFORECASTTOTALCOST,
  COLVARIANCEADJUSTEDBUDGET,
  COLPREVIOUSLYPAID,
  COLPAIDTHISMONTH,
  COLTOTEXPTODATE,
  COLCOSTTOCOMPLETE,
  COLPREVFORECASTTOTAL,
  COLCHANGEINPERIOD,
  rowFields,
  totalColumns,
  showHideRow,
  generateTableRowValues,
  getPreviouslyPaidValue,
  getTotExpToDateValue,
  getForecastTotal,
  UNAPPROVEDVARIATIONTITLE,
  TableCellShading,
  getRRsTotals,
  DIRECTCOSTSSUBTOTAL,
  RISKANDCONTINGENCYSUBTOTAL,
  GetExpenditureColumnValues,
  checkUndefined,
  APPROVEDVARIATIONTITLE,
  rrTotals,
  _CONSTRUCTIONCOSTROWS,
  CONSTRUCTIONCOSTSUMMARYROWS,
  MEASUREDWORKSTITLE,
} from "./CostReportTable.helpers";

let previousForecastTotalCost = 0;
let changeinperiodTotal = 0;
let totalexptodate = 0;
let totalpaidthismonth = 0;
let totalpreviouslypaid = 0;
let totalcosttocomplete = 0;

let _SELECTEDCR = undefined;
let DYNCONSTRUCTIONCOSTROWS = CONSTRUCTIONCOSTROWS;

export default function CostReportTable(props) {
  const { CR } = props;
  const { progressClaim } = props;
  const { comparisonCRs } = props;
  const { project } = props;
  const { RRs } = props;
  const { SELECTEDCR } = props;
  _SELECTEDCR = SELECTEDCR;

  let trades = SELECTEDCR.trades;
  let filteredTrades = trades.filter(function (trade) {
    return trade.sub_heading === "" || trade.sub_heading === null;
  });

  if (filteredTrades.length === trades.length) {
    DYNCONSTRUCTIONCOSTROWS = CONSTRUCTIONCOSTSUMMARYROWS.concat(
      _CONSTRUCTIONCOSTROWS
    );
  } else {
    DYNCONSTRUCTIONCOSTROWS = CONSTRUCTIONCOSTROWS;
  }

  totalColumns._previous_forecast_total = 0;
  totalColumns._cost_to_complete = 0;
  totalColumns._change_in_period = 0;
  totalColumns._previously_paid = 0;
  totalColumns._paid_this_month = 0;
  totalColumns._tot_exp_todate = 0;
  totalColumns._paid_this_month = 0;
  totalColumns._previously_paid = 0;

  previousForecastTotalCost = 0;
  changeinperiodTotal = 0;
  totalexptodate = 0;
  totalpaidthismonth = 0;
  totalpreviouslypaid = 0;
  totalcosttocomplete = 0;

  if (isEmpty(CR)) {
    return null;
  }
  const previousProgressClaimIndex = comparisonCRs.findIndex((object) => {
    return object.cost_report.id === CR.id;
  });

  const previousProgressClaim =
    previousProgressClaimIndex > 0
      ? comparisonCRs[previousProgressClaimIndex - 1].progress_claim
      : {};

  const previousCR =
    previousProgressClaimIndex > 0
      ? comparisonCRs[previousProgressClaimIndex - 1].cost_report
      : {};

  if (RRs !== undefined) {
    getRRsTotals(RRs);
  }

  return (
    <div className="table-container">
      <div className="cost-report-table">
        <CostReportTableHeadRowGroup values={TABLEGROUPHEADINGS} />
        <CostReportTableHeadRow values={TABLEHEADINGS} />

        {/* <CostReportTableRowGap title={translate("Trade Costs")} /> */}
        <GroupRows
          CR={CR}
          progressClaim={progressClaim}
          previousProgressClaim={previousProgressClaim}
          rows={DYNCONSTRUCTIONCOSTROWS}
          previousCR={previousCR}
          project={project}
          subTotalTitle={"Construction Costs Sub-Total"}
          SELECTEDCR={SELECTEDCR}
        />
        {project.enable_pc_client_directs && (
          <>
            {(project.enable_pc_enabling_works ||
              project.enable_pc_project_team_consultant_fees) && (
              <CostReportTableRowGap title={translate("Direct Costs")} />
            )}
            <GroupRows
              CR={CR}
              progressClaim={progressClaim}
              previousProgressClaim={previousProgressClaim}
              rows={CLIENTCOSTROWS}
              previousCR={previousCR}
              project={project}
              subTotalTitle={DIRECTCOSTSSUBTOTAL}
              SELECTEDCR={SELECTEDCR}
            />
          </>
        )}
        <CostReportTableRow
          CR={CR}
          progressClaim={progressClaim}
          title={translate(
            "Forecast Final Project Cost (Excl Risk,Contingency and Tax)"
          )}
          CRObjectKey={"forecast_cost_excl_risk_tax"}
          PCObjectKey={"forecast_cost_excl_risk_tax"}
          total
          subtotal
          previousProgressClaim={previousProgressClaim}
          previousCR={previousCR}
          project={project}
        />
        {project.enable_pc_contingency && (
          <>
            <CostReportTableRowGap title={translate("Risk and Contingency")} />
            <GroupRows
              CR={CR}
              progressClaim={progressClaim}
              previousProgressClaim={previousProgressClaim}
              rows={CONTINGENCYROWS}
              previousCR={previousCR}
              project={project}
              subTotalTitle={RISKANDCONTINGENCYSUBTOTAL}
              SELECTEDCR={SELECTEDCR}
            />
          </>
        )}

        <CostReportTableRow
          CR={CR}
          progressClaim={progressClaim}
          title={translate("Forecast Final Project Cost Excluding", {
            tax: isEmpty(CR.tax_type) ? "tax" : CR.tax_type,
          })}
          CRObjectKey={"forecast_cost_excl_tax"}
          PCObjectKey={"forecast_cost_excl_tax"}
          total
          subtotal
          previousProgressClaim={previousProgressClaim}
          previousCR={previousCR}
          project={project}
        />
        {showHideRow("tax", project) && (
          <CostReportTableRow
            CR={CR}
            progressClaim={progressClaim}
            title={translate("Tax ", {
              tax: isEmpty(CR.tax_type) ? "tax" : CR.tax_type,
              percent: isEmpty(CR.tax_percent) ? "" : CR.tax_percent + "%",
            })}
            CRObjectKey={"tax"}
            PCObjectKey={"tax"}
            previousProgressClaim={previousProgressClaim}
            previousCR={previousCR}
            project={project}
            total
            tax
          />
        )}
        {/* ARPITHAA */}
        {showHideRow("tax", project) && (
          <CostReportTableRow
            CR={CR}
            progressClaim={progressClaim}
            title={translate("Forecast Final Project Cost Including", {
              tax: isEmpty(CR.tax_type) ? "tax" : CR.tax_type,
              percent: isEmpty(CR.tax_percent) ? "" : CR.tax_percent + "%",
            })}
            CRObjectKey={"forecast_cost_incl_tax"}
            PCObjectKey={"forecast_cost_incl_tax"}
            total
            previousProgressClaim={previousProgressClaim}
            previousCR={previousCR}
            project={project}
          />
        )}
      </div>
    </div>
  );
}

function CostReportTableHeadRowGroup(props) {
  const { values } = props;
  const cells = [];

  values.forEach((value, i) => {
    if (i === 0) {
      cells.push(
        <div
          key={i}
          className="cost-report-table-cell-heading-group-description"
        >
          {value}
        </div>
      );
    } else if (i === 1 || i === 2) {
      cells.push(
        <div
          key={i}
          className="cost-report-table-cell-group-heading group-heading-width-3"
        >
          {value}
        </div>
      );
    } else if (i === 3 || i === 5) {
      cells.push(
        <div
          key={i}
          className="cost-report-table-cell-group-heading group-heading-width-4"
        >
          {value}
        </div>
      );
    } else {
      cells.push(
        <div
          key={i}
          className="cost-report-table-cell-group-heading group-heading-width-2"
        >
          {value}
        </div>
      );
    }
  });

  return <div className="cost-report-table-heading-row">{cells}</div>;
}

function CostReportTableHeadRow(props) {
  const { values } = props;
  const cells = [];
  values.forEach((value, i) => {
    if (i === 0) {
      cells.push(
        <div key={i} className="cost-report-table-cell-heading-description">
          {value}
        </div>
      );
    } else {
      cells.push(
        <div key={i} className="cost-report-table-cell-heading">
          <b>{value}</b>
        </div>
      );
    }
  });

  return <div className="cost-report-table-heading-row">{cells}</div>;
}

function GroupRows(props) {
  const { CR } = props;
  const { progressClaim } = props;
  const { previousProgressClaim } = props;
  const { rows } = props;
  const { previousCR } = props;
  const { project } = props;
  const { subTotalTitle } = props;
  const { SELECTEDCR } = props;

  const display = [];
  previousForecastTotalCost += totalColumns._previous_forecast_total;
  changeinperiodTotal += totalColumns._change_in_period;
  totalexptodate += totalColumns._tot_exp_todate;
  totalpaidthismonth += totalColumns._paid_this_month;
  totalpreviouslypaid += totalColumns._previously_paid;
  totalcosttocomplete += totalColumns._cost_to_complete;

  totalColumns._previous_forecast_total = 0;
  totalColumns._change_in_period = 0;
  totalColumns._tot_exp_todate = 0;
  totalColumns._paid_this_month = 0;
  totalColumns._previously_paid = 0;
  totalColumns._cost_to_complete = 0;

  rowFields.pop();
  rowFields.push(rows);
  // React requires unique key prop for each row in a table
  let subtotalKey = "";
  rows.forEach((row, i) => {
    subtotalKey = row.title + i + 1;
    if (row.CRObjectKey === "consultants_fees")
      display.push(
        <CostReportTableRowGap title={translate("Client Direct Costs")} />
      );
    if (row.title === MEASUREDWORKSTITLE)
      display.push(<CostReportTableRowGap title={translate("Trade Costs")} />);
    if (
      row.title === APPROVEDVARIATIONTITLE &&
      DYNCONSTRUCTIONCOSTROWS.filter(
        (r) => r.CRObjectKey === "construction_cost"
      ).length === 0
    )
      display.push(
        <CostReportTableRowGap title={translate("Construction Costs")} />
      );
    display.push(
      <CostReportTableRow
        key={row.title + i}
        CR={CR}
        progressClaim={progressClaim}
        title={row.title}
        CRObjectKey={row.CRObjectKey}
        PCObjectKey={row.PCObjectKey}
        previousProgressClaim={previousProgressClaim}
        previousCR={previousCR}
        project={project}
        SELECTEDCR={SELECTEDCR}
      />
    );
  });
  display.push(
    <CostReportTableRow
      key={subtotalKey}
      CR={CR}
      progressClaim={progressClaim}
      title={translate(subTotalTitle)}
      CRObjectKey={"sub_total"}
      PCObjectKey={subTotalTitle}
      previousProgressClaim={previousProgressClaim}
      previousCR={previousCR}
      project={project}
      total
      subtotal
    />
  );

  return display;
}

function GapRowCells() {
  let gapcells = [];
  let classType = "cost-report-table-cell";
  for (let key in totalColumns) {
    if (TableCellShading(gapcells.length + 1) !== undefined) {
      classType = TableCellShading(gapcells.length + 1);
    }
    gapcells.push(<div key={key} className={classType + " gap-cells-style"} />);
    classType = "cost-report-table-cell";
  }
  return gapcells;
}

function CostReportTableRowGap(props) {
  const { title } = props;
  return (
    <div className="cost-report-table-row-gap">
      <div className="cost-report-table-cell-description cost-report-table-cell-description-gap">
        <b>{title}</b>
      </div>
      {GapRowCells()}
    </div>
  );
}

export function CostReportTableRow(props) {
  const { CR } = props;
  const { title } = props;
  const { CRObjectKey } = props;
  const { subtotal, total, tax } = props;
  const { customTitle } = props;
  const { progressClaim } = props;
  const { PCObjectKey } = props;
  const { previousProgressClaim } = props;
  const { previousCR } = props;
  const { project } = props;
  const { SELECTEDCR } = props;

  const display = [];
  const cells = [];

  let totExpToDate = 0;
  let previouslyPaid = 0;
  let type = "cost-report-table-row";

  if (CRObjectKey === "forecast_cost_excl_tax") {
    totalColumns._previous_forecast_total = checkUndefined(
      previousForecastTotalCost
    );
    totalColumns._change_in_period = checkUndefined(changeinperiodTotal);
    totalColumns._tot_exp_todate = checkUndefined(totalexptodate);
    totalColumns._paid_this_month = checkUndefined(totalpaidthismonth);
    totalColumns._previously_paid = checkUndefined(totalpreviouslypaid);
    totalColumns._cost_to_complete = checkUndefined(totalcosttocomplete);
    rowFields.pop();
    rowFields.push(
      CONSTRUCTIONCOSTROWS.concat(CLIENTCOSTROWS).concat(CONTINGENCYROWS)
    );
  }
  if (CRObjectKey === "forecast_cost_excl_risk_tax") {
    rowFields.pop();
    rowFields.push(CONSTRUCTIONCOSTROWS.concat(CLIENTCOSTROWS));
  }

  if (total) {
    type = "cost-report-table-row-total";
  }

  if (subtotal) {
    type = "cost-report-table-row-subtotal";
  }

  if (tax) {
    type = "cost-report-table-row";
  }

  if (CRObjectKey === "forecast_cost_excl_risk_tax") {
    type += " cost-report-table-row-dark";
  }
  if (CRObjectKey === "forecast_cost_excl_tax")
    type = !showHideRow("tax", project)
      ? "cost-report-table-row-total"
      : type + " cost-report-table-row-dark";

  totExpToDate = getTotExpToDateValue(
    progressClaim,
    PCObjectKey,
    CR.tax_percent,
    SELECTEDCR
  );

  previouslyPaid = getPreviouslyPaidValue(
    previousProgressClaim,
    PCObjectKey,
    CR.tax_percent,
    SELECTEDCR
  );
  const values = generateTableRowValues(CR, SELECTEDCR, CRObjectKey, title);
  values.push(
    checkUndefined(previouslyPaid),
    totExpToDate - previouslyPaid,
    totExpToDate,
    "",
    getForecastTotal(previousCR, CRObjectKey, title),
    0
  );

  // Try and prove that we don't display this row
  values.forEach((value) => {
    switch (value) {
      // Don't display if values are all 0 // Don't display if values are all empty
      case 0:
      case "":
        display.push(false);
        break;
      // If even 1 is not 0 or empty we display the row
      default:
        display.push(true);
        break;
    }
  });

  if (display.every((e) => e === false)) {
    return null;
  }

  // Determine Label
  let displayTitle = typeof title === "string" ? translate(title) : title;
  if (customTitle) {
    displayTitle = CR[`${CRObjectKey}_label`];
  }
  // Add Label
  values.unshift(displayTitle);
  ProcessCells(values, props, cells, totExpToDate);

  if (showHideRow(CRObjectKey, project))
    return CostReportTableCells(CRObjectKey, type, cells, CR.project_id);
  return null;
}

function CreateCostReportTableCell(i, value, cellObject, cellType, isBold) {
  if (value === "") {
    cellObject.push(<div key={i} className={cellType}></div>);
  } else {
    cellObject.push(
      <div key={i} className={cellType}>
        {isBold && <b>{commaWithNaN(Math.round(value))}</b>}
        {!isBold && commaWithNaN(Math.round(value))}
      </div>
    );
  }
}

function CostReportTableCells(CRObjectKey, type, cells, projectId) {
  let linkHref = "";
  const routeUrl = "/project/" + projectId + "/post-contract/";
  switch (CRObjectKey) {
    case "construction_cost":
    case "construction_cost_works":
    case "construction_cost_prelims":
    case "construction_cost_prov_sums":
    case "construction_cost_other2":
    case "construction_cost_cdp":
      linkHref = "trade-costs";
      break;
    case "variations":
      linkHref = "variations";
      break;
    case "provisional_quantities":
      linkHref = "provisional-quantities";
      break;
    case "provisional_sums":
      linkHref = "provisional-sums";
      break;
    case "delays_and_extensions_of_time":
      linkHref = "delays";
      break;
    case "consultants_fees":
      linkHref = "consultant-fees";
      break;
    case "client_staffing":
      linkHref = "client-directs";
      break;
    case "authority_fees":
      linkHref = "client-directs";
      break;
    case "legal_fees":
      linkHref = "client-directs";
      break;
    case "ffe":
      linkHref = "client-directs";
      break;
    case "loose_furniture":
      linkHref = "client-directs";
      break;
    case "workstations":
      linkHref = "client-directs";
      break;
    case "audio_visual":
      linkHref = "client-directs";
      break;
    case "specialist_equipment":
      linkHref = "client-directs";
      break;
    case "ict":
      linkHref = "client-directs";
      break;
    case "relocation":
      linkHref = "client-directs";
      break;
    case "other1":
      linkHref = "client-directs";
      break;
    case "other2":
      linkHref = "client-directs";
      break;
    case "other3":
      linkHref = "client-directs";
      break;
    case "enabling_works":
      linkHref = "enabling-works";
      break;
    case "project_team_consulting_fees":
      linkHref = "consultant-fees";
      break;
    case "risk_allowance":
      linkHref = "risk-register";
      break;
    case "project_contingency":
      linkHref = "contingency";
      break;
    default:
      return <div className={type}>{cells}</div>;
  }
  return (
    <Link to={routeUrl + linkHref} className="cost-report-table-row-link">
      {cells}
    </Link>
  );
}

function ProcessCells(values, props, cells, totExpToDate, previouslyPaid) {
  const { CR } = props;
  const { title } = props;
  const { CRObjectKey } = props;
  const { subtotal, total, tax } = props;
  const { PCObjectKey } = props;
  const { previousCR } = props;
  const { project } = props;

  let revContValue = 0;
  let antVariationsValue = 0;
  let varAdjustBudget = 0;
  let forecastTotalCost = 0;
  let totCostCompl = 0;
  let paidThisMonth = 0;

  // Process Cells
  values.forEach((value, i) => {
    let cellType = "cost-report-table-cell";
    switch (i) {
      case COLREVISEDCONTRACTVALUE: //6
        revContValue += values[4] + values[5];
        break;
      case COLANTICIPATEDVARIATIONS: //10
        antVariationsValue = values[7] + values[8] + values[9];
        break;
      case COLFORECASTTOTALCOST: //11
        forecastTotalCost = revContValue + antVariationsValue;
        break;
      case COLVARIANCEADJUSTEDBUDGET: //12
        varAdjustBudget = values[COLADJUSTEDBUDGET] - forecastTotalCost;
        break;
      case COLCOSTTOCOMPLETE: //16
        if (
          (total === undefined || !total) &&
          showHideRow(CRObjectKey, project) &&
          PCObjectKey !== ""
        ) {
          if (title === UNAPPROVEDVARIATIONTITLE) {
            totExpToDate = 0;
          }
          totCostCompl = forecastTotalCost - totExpToDate;
          totalColumns._cost_to_complete += totCostCompl;
          value = totCostCompl;
        }

        break;
      case COLPREVFORECASTTOTAL: //17
        if (
          (total === undefined || !total) &&
          showHideRow(CRObjectKey, project)
        ) {
          // if (title !== UNAPPROVEDVARIATIONTITLE)
          totalColumns._previous_forecast_total += value;
        }

        break;
      case COLCHANGEINPERIOD: //18
        if (CRObjectKey === "forecast_cost_excl_tax") {
          totalColumns._change_in_period += showHideRow(
            "risk_allowance",
            project
          )
            ? rrTotals.risk_analysis
            : 0;
        } else {
          if (
            (total === undefined || !total) &&
            showHideRow(CRObjectKey, project)
          ) {
            if (
              title === UNAPPROVEDVARIATIONTITLE ||
              title === APPROVEDVARIATIONTITLE
            ) {
              totalColumns._change_in_period +=
                forecastTotalCost -
                getForecastTotal(previousCR, CRObjectKey, title);
            } else {
              totalColumns._change_in_period +=
                forecastTotalCost -
                getForecastTotal(previousCR, CRObjectKey, "");
            }
          }
        }
        break;
      case COLPREVIOUSLYPAID:
        if (
          (total === undefined || !total) &&
          showHideRow(CRObjectKey, project) &&
          title !== UNAPPROVEDVARIATIONTITLE
        ) {
          totalColumns._previously_paid += values[i];
        }
        break;
      case COLPAIDTHISMONTH:
        paidThisMonth = values[COLTOTEXPTODATE] - values[COLPREVIOUSLYPAID];
        if (
          (total === undefined || !total) &&
          showHideRow(CRObjectKey, project) &&
          title !== UNAPPROVEDVARIATIONTITLE
        ) {
          totalColumns._paid_this_month += paidThisMonth;
        }
        break;
      case COLTOTEXPTODATE:
        if (
          (total === undefined || !total) &&
          showHideRow(CRObjectKey, project) &&
          title !== UNAPPROVEDVARIATIONTITLE
        ) {
          totalColumns._tot_exp_todate += values[i];
        }
        break;
      default:
        break;
    }

    if (!total && !subtotal) {
      if (TableCellShading(i) !== undefined) cellType = TableCellShading(i);
    }

    if (i === 0 && total) {
      let className = !tax
        ? "cost-report-table-cell-description description-white"
        : "cost-report-table-cell-description";
      cells.push(
        <div key={i} className={className}>
          <b>{value}</b>
        </div>
      );
    }

    if (i !== 0 && total) {
      let valueToBeInjToCell = 0;

      let subtotalsTobeEliminated = [
        RISKANDCONTINGENCYSUBTOTAL,
        // DIRECTCOSTSSUBTOTAL,
      ];
      let columnsTobeEmpty = [
        COLPREVIOUSLYPAID,
        COLPAIDTHISMONTH,
        COLTOTEXPTODATE,
        COLCOSTTOCOMPLETE,
      ];
      if (
        subtotalsTobeEliminated.includes(PCObjectKey) &&
        columnsTobeEmpty.includes(i)
      ) {
        cells.push(<div key={i} className={cellType}></div>);
      } else {
        switch (
          i // 6 10 11 12
        ) {
          case COLREVISEDCONTRACTVALUE:
            valueToBeInjToCell =
              totalColumns._contract_sum + totalColumns._approved;
            totalColumns._revised_contract_value = valueToBeInjToCell;
            if (tax) {
              valueToBeInjToCell = (valueToBeInjToCell * CR.tax_percent) / 100;
            } else {
              valueToBeInjToCell = subtotal
                ? valueToBeInjToCell
                : (valueToBeInjToCell * (100 + CR.tax_percent)) / 100;
            }
            break;
          case COLANTICIPATEDVARIATIONS:
            valueToBeInjToCell =
              totalColumns._submitted +
              totalColumns._forecast +
              totalColumns._adjustment;
            totalColumns._anticipated_variations =
              checkUndefined(valueToBeInjToCell);
            if (tax) {
              valueToBeInjToCell = (valueToBeInjToCell * CR.tax_percent) / 100;
            } else {
              valueToBeInjToCell = subtotal
                ? valueToBeInjToCell
                : (valueToBeInjToCell * (100 + CR.tax_percent)) / 100;
            }
            break;
          case COLFORECASTTOTALCOST:
            valueToBeInjToCell =
              totalColumns._revised_contract_value +
              totalColumns._anticipated_variations;
            totalColumns._forecast_total_cost = valueToBeInjToCell;
            if (tax) {
              valueToBeInjToCell = (valueToBeInjToCell * CR.tax_percent) / 100;
            } else {
              valueToBeInjToCell = subtotal
                ? valueToBeInjToCell
                : (valueToBeInjToCell * (100 + CR.tax_percent)) / 100;
            }
            break;
          case COLVARIANCEADJUSTEDBUDGET:
            valueToBeInjToCell =
              totalColumns._adjusted_budget - totalColumns._forecast_total_cost;
            totalColumns._variance_adj_budget = valueToBeInjToCell;
            if (tax) {
              valueToBeInjToCell = (valueToBeInjToCell * CR.tax_percent) / 100;
            } else {
              valueToBeInjToCell = subtotal
                ? valueToBeInjToCell
                : (valueToBeInjToCell * (100 + CR.tax_percent)) / 100;
            }
            break;
          case COLCHANGEINPERIOD:
            valueToBeInjToCell = GetExpenditureColumnValues(
              CRObjectKey,
              valueToBeInjToCell,
              changeinperiodTotal,
              totalColumns._change_in_period,
              total,
              subtotal,
              tax,
              CR.tax_percent
            );
            break;
          case COLPREVIOUSLYPAID:
            valueToBeInjToCell = GetExpenditureColumnValues(
              CRObjectKey,
              valueToBeInjToCell,
              totalpreviouslypaid,
              totalColumns._previously_paid,
              total,
              subtotal,
              tax,
              CR.tax_percent
            );

            break;
          case COLTOTEXPTODATE:
            valueToBeInjToCell = GetExpenditureColumnValues(
              CRObjectKey,
              valueToBeInjToCell,
              totalexptodate,
              totalColumns._tot_exp_todate,
              total,
              subtotal,
              tax,
              CR.tax_percent
            );

            break;
          case COLPAIDTHISMONTH:
            valueToBeInjToCell = GetExpenditureColumnValues(
              CRObjectKey,
              valueToBeInjToCell,
              totalpaidthismonth,
              totalColumns._paid_this_month,
              total,
              subtotal,
              tax,
              CR.tax_percent
            );

            break;
          case COLCOSTTOCOMPLETE:
            valueToBeInjToCell = GetExpenditureColumnValues(
              CRObjectKey,
              valueToBeInjToCell,
              totalcosttocomplete,
              totalColumns._cost_to_complete,
              total,
              subtotal,
              tax,
              CR.tax_percent
            );

            break;
          case COLPREVFORECASTTOTAL:
            valueToBeInjToCell = GetExpenditureColumnValues(
              CRObjectKey,
              valueToBeInjToCell,
              previousForecastTotalCost,
              totalColumns._previous_forecast_total,
              total,
              subtotal,
              tax,
              CR.tax_percent
            );

            break;
          default:
            valueToBeInjToCell = getColumnTotal(CR, project, i, _SELECTEDCR);
            if (tax) {
              valueToBeInjToCell = (valueToBeInjToCell * CR.tax_percent) / 100;
            } else {
              if (total && !subtotal) {
                valueToBeInjToCell =
                  (valueToBeInjToCell * (100 + CR.tax_percent)) / 100;
              }
            }
            break;
        }
        CreateCostReportTableCell(
          i,
          checkUndefined(valueToBeInjToCell),
          cells,
          cellType,
          false
        );
      }
    }

    if (i === 0 && !total) {
      cells.push(
        <div key={i} className="cost-report-table-cell-description">
          {value}
        </div>
      );
    }

    if (i !== 0 && !total) {
      if (
        PCObjectKey === "" &&
        i > COLVARIANCEADJUSTEDBUDGET &&
        i <= COLCOSTTOCOMPLETE
      ) {
        value = "";
        paidThisMonth = "";
        totCostCompl = "";
      }

      let valueToBeInjToCell = 0;
      switch (
        i //6 10 11 12 16 14
      ) {
        case COLREVISEDCONTRACTVALUE:
          valueToBeInjToCell = revContValue;
          break;
        case COLANTICIPATEDVARIATIONS:
          valueToBeInjToCell = antVariationsValue;
          break;
        case COLFORECASTTOTALCOST:
          valueToBeInjToCell = forecastTotalCost;
          break;
        case COLVARIANCEADJUSTEDBUDGET:
          valueToBeInjToCell = varAdjustBudget;
          break;
        case COLCOSTTOCOMPLETE:
          valueToBeInjToCell = totCostCompl;
          if (title === UNAPPROVEDVARIATIONTITLE) {
            valueToBeInjToCell = forecastTotalCost - 0;
          }
          break;
        case COLPAIDTHISMONTH:
          valueToBeInjToCell = paidThisMonth;
          break;
        case COLCHANGEINPERIOD:
          if (
            title === UNAPPROVEDVARIATIONTITLE ||
            title === APPROVEDVARIATIONTITLE
          ) {
            valueToBeInjToCell =
              forecastTotalCost -
              getForecastTotal(previousCR, CRObjectKey, title);
            break;
          }
          valueToBeInjToCell =
            forecastTotalCost - getForecastTotal(previousCR, CRObjectKey, "");
          break;
        default:
          valueToBeInjToCell = value;
          break;
      }
      if (title === UNAPPROVEDVARIATIONTITLE && MakezerosForVariations(i) === 0)
        valueToBeInjToCell = 0;
      CreateCostReportTableCell(
        i,
        checkUndefined(valueToBeInjToCell),
        cells,
        cellType,
        false
      );
    }
  });
}

const MakezerosForVariations = (columnNo, totExpToDate) => {
  switch (columnNo) {
    case COLPREVIOUSLYPAID:
    case COLPAIDTHISMONTH:
    case COLTOTEXPTODATE: {
      return 0;
    }
    default:
      break;
  }
};
