import { commaToFixed, comma } from "../../helpers/common.functions";
import PropTypes from "prop-types";

export const headerTitles = {
  //  benchmarkId : "Benchmark Id",
  code: { content: "Elemental Code", type: "text" },
  reserved: { content: "Reserved", type: "number" },
  description: { content: "Elemental Code", type: "description" },
  total: { content: "Total Cost", type: "currency" },
  gia_rate: { content: "Cost per m2 GIA", type: "currency" },
  local_region_rate: {
    content: "Cost per m2 Local Region Area",
    type: "currency",
  },
  quantity: { content: "Elemental Quantity", type: "number" },
  unit: { content: "Elemental Unit", type: "text" },
  rate: { content: "Rate", type: "currency" },
  // id: "id"
};

Table.propTypes = {
  headerRow: PropTypes.array,
  rows: PropTypes.array,
};
export function Table(props) {
  const { headerRow } = props;
  const { rows } = props;
  return (
    <div className="info-table-container">
      {headerRow}

      <div className="info-table"> {rows}</div>
    </div>
  );
}

Row.propTypes = {
  Cells: PropTypes.array,
  rowSpecificClass: PropTypes.string,
};
export function Row(props) {
  const { Cells } = props;
  let { rowSpecificClass } = props;
  rowSpecificClass = "info-table-row " + rowSpecificClass;
  return <div className={rowSpecificClass}>{Cells}</div>;
}

Cell.propTypes = {
  content: PropTypes.string,
  cellSpecificClass: PropTypes.string,
};
export function Cell(props) {
  const { content } = props;
  let { cellSpecificClass } = props;
  cellSpecificClass = "info-table-cell " + cellSpecificClass;
  return <div className={cellSpecificClass}>{content}</div>;
}

export function formatNumber(type, cellContent) {
  switch (type) {
    case "currency":
      return commaToFixed(cellContent);
    case "number":
      return comma(cellContent);
    default:
      return cellContent;
  }
}

export function groupBy(objectArray, property) {
  return objectArray.reduce((acc, obj) => {
    const key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    // Add object to list for given key's value
    acc[key].push(obj);
    return acc;
  }, {});
}