import React, { useMemo } from "react";
import PropTypes from "prop-types";

import isEmpty from "../../validation/is-empty";

import { useTPI } from "../../api/tender-price-index/TenderPriceIndexingAPI";
import { useBenchmarksWithRows } from "../../api/benchmarking/BenchmarkingAPI";

import Spinner from "../common/Spinner";
import BenchmarkSection from "../benchmarking-project/BenchmarkSection";
import BenchmarkCreateAnalysisDisclaimer from "./BenchmarkCreateAnalysisDisclaimer";

import {
  linkBenchmarks,
  prepareOnBenchmarkAnonymityToggle,
} from "./BenchmarkCreate.functions";

/// props validation | SQ(javascript:S6774)
BenchmarkCreateAnalysis.propTypes = {
  disclaimerDisplay: PropTypes.object,
  title: PropTypes.string,
  unitOfMeasure: PropTypes.string,
  currencySymbol: PropTypes.string,
  region: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  baseDate: PropTypes.string,
  selectedBenchmarkIds: PropTypes.array.isRequired,
  LFI: PropTypes.array.isRequired,
};
///
export default function BenchmarkCreateAnalysis(props) {
  const { disclaimerDisplay } = props;
  const { title, unitOfMeasure, currencySymbol } = props;
  const { region, country, city } = props;
  const { baseDate } = props;
  const { selectedBenchmarkIds } = props;
  const { LFI } = props;

  // get TPI
  const { TPILoading, TPI } = useTPI(true, region, country);

  // consider the first of benchmarkIds as that of the reference benchmark (thisBenchmark)
  const refBenchmarkIds = useMemo(
    () => selectedBenchmarkIds.slice(0, 1),
    [selectedBenchmarkIds]
  );
  // collect the rest of the benchmarkIds
  const restBenchmarkIds = useMemo(
    () => selectedBenchmarkIds.slice(1),
    [selectedBenchmarkIds]
  );

  // fetch the reference benchmark
  const {
    loading: refBenchmarksLoading,
    benchmarksWithRows: refBenchmarks,
    setBenchmarksWithRows: setRefBenchmarks,
  } = useBenchmarksWithRows(refBenchmarkIds);

  // fetch the rest of the benchmarks
  const {
    loading: benchmarksLoading,
    benchmarksWithRows: restBenchmarks,
    setBenchmarksWithRows: setRestBenchmarks,
  } = useBenchmarksWithRows(restBenchmarkIds);

  // handle loading scenarios
  if (TPILoading || benchmarksLoading || refBenchmarksLoading) {
    return <Spinner />;
  }

  // loading completed

  const refBenchmark = refBenchmarks?.[0];

  // link benchmarks to thisBenchmark
  linkBenchmarks({
    thisBenchmark: refBenchmark,
    benchmarks: restBenchmarks,
  });

  const showDisclaimer = !isEmpty(refBenchmark) && !isEmpty(restBenchmarks);

  const onBenchmarkAnonymityToggle = prepareOnBenchmarkAnonymityToggle({
    refBenchmarks,
    setRefBenchmarks,
    restBenchmarks,
    setRestBenchmarks,
  });

  return (
    <div className="master-benchmarking">
      <BenchmarkCreateAnalysisDisclaimer
        disclaimerDisplay={disclaimerDisplay}
        show={showDisclaimer}
      />
      <BenchmarkSection
        caller={"MASTER_BENCHMARKING"}
        title={title}
        unitOfMeasure={unitOfMeasure}
        currencySymbol={currencySymbol}
        region={region}
        country={country}
        city={city}
        thisBenchmark={refBenchmark}
        benchmarks={restBenchmarks}
        setBenchmarks={setRestBenchmarks}
        baseDate={baseDate}
        TPI={TPI}
        LFI={LFI}
        onBenchmarkAnonymityToggle={onBenchmarkAnonymityToggle}
      />
    </div>
  );
}
