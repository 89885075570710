import PropTypes from "prop-types";
import Modal from "../modal/Modal";

import {
  Label,
  Select,
  SelectFilter,
} from "../project-create/CreateProject.components";

import {
  RegionSelect,
  CountrySelect,
  CitySelect,
} from "../locations/LocationSelect";

const FormControlComponents = {
  Label,
  Text,
  Select,
  SelectFilter,
  RegionSelect,
  CountrySelect,
  CitySelect,
  Input,
};

/// props validation | SQ(javascript:S6774)
Input.propTypes = {
  value: PropTypes.string.isRequired,
  set: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  pattern: PropTypes.string,
  type: PropTypes.string,
  minlength: PropTypes.string,
  maxlength: PropTypes.string,
};
///
function Input(props) {
  const { value, set } = props;
  const { placeholder, pattern } = props;
  const { type } = props;
  const { minlength, maxlength } = props;
  return (
    <input
      className={"create-project-input"}
      defaultValue={value}
      onChange={(e) => set(e.target.value)}
      placeholder={placeholder}
      pattern={pattern}
      type={type}
      minLength={minlength}
      maxLength={maxlength}
      onKeyDown={(e) => validateInput(e, type)}
    />
  );
}

/// props validation | SQ(javascript:S6774)
Text.propTypes = {
  value: PropTypes.element.isRequired,
};
///
function Text(props) {
  const { value } = props;
  return <div className="text">{value}</div>;
}

// Components such as Select, SelectFilter already include <Label ... /> within
// Components such as Text require no label
// Components other than these require to be wrapped within <LabelComponent />
export const ComponentNeedsLabel = (Component) =>
  ![Text, Select, SelectFilter].includes(Component);

/// props validation | SQ(javascript:S6774)
LabelComponent.propTypes = {
  label: PropTypes.object.isRequired,
  Component: PropTypes.func.isRequired,
};
///
function LabelComponent(props) {
  const { label } = props;
  const { Component } = props;

  return (
    <div className="create-project-input-row">
      <Label label={label} />
      <Component {...props} />
    </div>
  );
}

/// props validation | SQ(javascript:S6774)
Form.propTypes = {
  label: PropTypes.any,
  formControls: PropTypes.array.isRequired,
};
///
export function Form(props) {
  const { label, formControls } = props;

  return (
    <>
      <h1 className="display-4 form-title">{label}</h1>
      {formControls.map((control) => {
        const Component = FormControlComponents[control.component];

        return ComponentNeedsLabel(Component) ? (
          <LabelComponent
            key={control.key}
            Component={Component}
            {...control}
          />
        ) : (
          <Component key={control.key} {...control} />
        );
      })}
    </>
  );
}

/// props validation | SQ(javascript:S6774)
FormError.propTypes = {
  error: PropTypes.any,
};
///
export function FormError(props) {
  const { error } = props;

  return <div className="modal-error">{error}</div>;
}

/// props validation | SQ(javascript:S6774)
SaveForm.propTypes = {
  onSave: PropTypes.func,
};
///
export function SaveForm(props) {
  const { onSave } = props;

  return (
    <ActionForm
      {...props}
      buttonClassName="modal-save-button"
      label={"Save"}
      onAction={onSave}
    />
  );
}

/// props validation | SQ(javascript:S6774)
CloseForm.propTypes = {
  onClose: PropTypes.func,
};
///
export function CloseForm(props) {
  const { onClose } = props;

  return (
    <ActionForm
      {...props}
      buttonClassName="modal-close-button"
      label={"Close"}
      onAction={onClose}
    />
  );
}

/// props validation | SQ(javascript:S6774)
AcceptForm.propTypes = {
  isAcceptReady: PropTypes.bool,
  onAccept: PropTypes.func,
};
///
export function AcceptForm(props) {
  const { isAcceptReady } = props;
  const { onAccept } = props;

  return (
    <ActionForm
      {...props}
      buttonClassName={
        isAcceptReady ? "modal-save-button" : "modal-close-button"
      }
      label={"Accept"}
      onAction={onAccept}
    />
  );
}

/// props validation | SQ(javascript:S6774)
ActionForm.propTypes = {
  isActionButtonHidden: PropTypes.bool,
  buttonClassName: PropTypes.string,
  label: PropTypes.string,
  setModal: PropTypes.func,
  forms: PropTypes.array,
  onAction: PropTypes.func,
};
///
export function ActionForm(props) {
  const { isActionButtonHidden } = props;

  if (isActionButtonHidden) {
    return <></>;
  }

  const { buttonClassName, label } = props;
  const { setModal } = props;
  const { forms } = props;
  const { onAction } = props;

  return (
    <button
      className={buttonClassName}
      onClick={async (e) => {
        let success = true;
        if (typeof onAction === "function") {
          success = await onAction(e, forms);
        }
        success && setModal(false);
      }}
    >
      {label}
    </button>
  );
}

function validateInput(e, type) {
  let key = parseInt(e.keyCode);
  switch (type) {
    case "numberonly":
      if (key !== 8 && key !== 46 && (key < 48 || key > 57)) {
        e.preventDefault();
      }
      break;
    case "text":
    case "telNo":
    default:
      return;
  }
}

/// props validation | SQ(javascript:S6774)
ButtonWithModal.propTypes = {
  buttonLabel: PropTypes.any,
  buttonClassName: PropTypes.string,
  onClick: PropTypes.func,
  ModalComponent: PropTypes.func,
  label: PropTypes.any,
  show: PropTypes.bool,
  setShow: PropTypes.func,
};
///
export function ButtonWithModal(props) {
  const { buttonLabel, buttonClassName, onClick } = props;
  const { label, ModalComponent, show, setShow } = props;

  return (
    <>
      <button className={buttonClassName} onClick={onClick}>
        {buttonLabel}
      </button>
      <Modal
        Component={ModalComponent}
        modal={show}
        setModal={setShow}
        title={label}
        {...props}
      />
    </>
  );
}
