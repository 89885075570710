import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import isEmpty from "../../validation/is-empty";
import { extractYearFromISODateString } from "../../helpers/common.functions";

import BenchmarkingAnonymity from "./BenchmarkingAnonymity";
import BenchmarkingDisplayTable from "./BenchmarkingDisplayTable";
import BenchmarkingMetrics from "./BenchmarkingMetrics";
import AverageCostCategories from "./AverageCostCategories";
import SectionTitleControl from "./SectionTitleControl";
import BenchmarkingHeatMap from "./BenchmarkingHeatMap";
import BenchmarkingCostplanComparisons from "./BenchmarkingCostplanComparisons";
import ProjectComparison from "./ProjectComparison";

import {
  calculateEscalationBenchmarkingDashboard,
  toggleEscalationBenchmarkingDashboard,
} from "../../helpers/TPI.functions";

import "./BenchmarkSection.css";
import { translate, tranStr } from "../../utils/translation";

import CostBenchmarking from "../charts/CostBenchmarking";
import ContractorOnCostComparisonChart from "../charts/ContractorOnCostComparisonChart";
import { useCbs } from "../../api/cbs/CbsAPI";

/// props validation | SQ(javascript:S6774)
BenchmarkSection.propTypes = {
  caller: PropTypes.string,
  thisBenchmark: PropTypes.object,
  benchmarks: PropTypes.array,
  is_locality_factor_active: PropTypes.bool,
  locality_factor: PropTypes.number,
  title: PropTypes.string,
  unitOfMeasure: PropTypes.string,
  currencySymbol: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  baseDate: PropTypes.string,
  TPI: PropTypes.object,
  LFI: PropTypes.array,
  onBenchmarkAnonymityToggle: PropTypes.func,
  settings: PropTypes.array,
  onSettingsToggle: PropTypes.func,
};
///
export default function BenchmarkSection(props) {
  const { caller } = props;

  // for certain callers, render charts without highlighting the first entity
  const skipHighlight = ["MASTER_BENCHMARKING"].includes(caller);

  const { thisBenchmark } = props;
  const { benchmarks } = props;
  const { is_locality_factor_active, locality_factor } = props;
  const { title, unitOfMeasure, currencySymbol, city, country } = props;
  const { baseDate } = props;
  const { TPI } = props;
  const { LFI } = props;
  const { onBenchmarkAnonymityToggle } = props;
  const { settings } = props;
  const { onSettingsToggle } = props;

  const { cbs } = useCbs(thisBenchmark?.standard);

  const [futureYear, setFutureYear] = useState(0);
  const [isEscalated, setIsEscalated] = useState(true);
  const [isElementalUnitRate, setIsElementalUnitRate] = useState(false);

  // Set the future year to the base date of the estimate or benchmark
  useEffect(() => {
    if (!isEmpty(thisBenchmark)) {
      // if baseDate is available in props, prefer it over thisBenchmark.base_date
      const base_date = baseDate || thisBenchmark.base_date;
      // pick year from date
      const baseYear = extractYearFromISODateString(base_date);
      // set year for use with TPI / Escalation
      setFutureYear(baseYear);
    }
  }, [thisBenchmark, baseDate]);

  // Return null if empty data
  if (isEmpty(benchmarks) || isEmpty(thisBenchmark)) {
    return (
      <div className="benchmark-section-empty">
        {translate("No benchmarks selected")}
      </div>
    );
  }

  // Escalation
  // Derive the escalation
  calculateEscalationBenchmarkingDashboard(
    TPI,
    LFI,
    thisBenchmark,
    benchmarks,
    futureYear,
    city,
    is_locality_factor_active,
    locality_factor
  );

  // Toggle escalation on and off
  toggleEscalationBenchmarkingDashboard(isEscalated, thisBenchmark, benchmarks);
  return (
    <div className="benchmarking-estimate-section">
      <div className="estimate-benchmarking-dashboard">
        <div className="estimate-benchmarking-dashboard-horizontal-charts">
          <ProjectComparison
            caller={caller}
            skipHighlight={skipHighlight}
            unitOfMeasure={unitOfMeasure}
            thisBenchmark={thisBenchmark}
            benchmarks={benchmarks}
            isEscalated={isEscalated}
            futureYear={futureYear}
          />
          <div className="estimate-benchmarking-dashboard-vertical-charts">
            <AverageCostCategories
              caller={caller}
              title={title}
              thisBenchmark={thisBenchmark}
              benchmarks={benchmarks}
            />
          </div>
        </div>
        <div className="estimate-benchmarking-dashboard-horizontal-charts">
          <CostBenchmarking
            caller={caller}
            unitOfMeasure={unitOfMeasure}
            title={title}
            thisBenchmark={thisBenchmark}
            benchmarks={benchmarks}
          />
          <div className="estimate-benchmarking-dashboard-vertical-charts">
            <ContractorOnCostComparisonChart
              title={title}
              thisBenchmark={thisBenchmark}
              benchmarks={benchmarks}
              cbs={cbs}
            />
          </div>
        </div>

        <div className="benchmarking-table-row">
          <BenchmarkingDisplayTable
            skipHighlight={skipHighlight}
            thisBenchmark={thisBenchmark}
            benchmarks={benchmarks}
            isEscalated={isEscalated}
            setIsEscalated={setIsEscalated}
            unitOfMeasure={unitOfMeasure}
            country={country}
            currencySymbol={currencySymbol}
            cbs={cbs}
          />
          <BenchmarkingAnonymity
            caller={caller}
            thisBenchmark={thisBenchmark}
            benchmarks={benchmarks}
            onBenchmarkAnonymityToggle={onBenchmarkAnonymityToggle}
          />
        </div>
      </div>
      <SectionTitleControl
        title={translate("Dataset Comparison")}
        isElementalUnitRate={isElementalUnitRate}
        setIsElementalUnitRate={setIsElementalUnitRate}
      />
      <BenchmarkingHeatMap
        skipHighlight={skipHighlight}
        title={title}
        unitOfMeasure={unitOfMeasure}
        currencySymbol={currencySymbol}
        thisBenchmark={thisBenchmark}
        benchmarks={benchmarks.filter((b) => b.source !== "FORM")}
        isElementalUnitRate={isElementalUnitRate}
        selectedCostType="Construction Cost"
        costTypeLabel={tranStr("Construction")}
        cbs={cbs}
      />
      <BenchmarkingHeatMap
        skipHighlight={skipHighlight}
        title={title}
        unitOfMeasure={unitOfMeasure}
        currencySymbol={currencySymbol}
        thisBenchmark={thisBenchmark}
        benchmarks={benchmarks.filter((b) => b.source !== "FORM")}
        isElementalUnitRate={isElementalUnitRate}
        selectedCostType="Project Cost"
        costTypeLabel={tranStr("Project")}
        cbs={cbs}
      />
      <BenchmarkingCostplanComparisons
        skipHighlight={skipHighlight}
        thisBenchmark={thisBenchmark}
        benchmarks={benchmarks.filter((b) => b.source !== "FORM")}
        isElementalUnitRate={isElementalUnitRate}
        selectedCostType="On Costs"
        costType="Project"
        costTypeLabel={tranStr("Project")}
        cbs={cbs}
      />
      <BenchmarkingMetrics
        skipHighlight={skipHighlight}
        thisBenchmark={thisBenchmark}
        benchmarks={benchmarks}
        currencySymbol={currencySymbol}
        settings={settings}
        onSettingsToggle={onSettingsToggle}
      />
    </div>
  );
}
