// React
import React, { useState } from "react";

import isEmpty from "../../validation/is-empty";

// Components
import Table from "../table/Table";

// Functions
import { tranStr } from "../../utils/translation";
import { columns } from "./PostContractSelectListTable.functions";

export default function PostContractSelectListTable(props) {
  const { data } = props;
  const { selectedData } = props;
  const { setSelectedItems } = props;

  const [selectedRow, setSelectedRow] = useState({});
  const [modal, setModal] = useState(false);

  if (isEmpty(data)) {
    return <div></div>;
  }

  return (
    <Table
      title={tranStr("Risk Register")}
      tableArray={data}
      columns={columns()}
      tableSize={10}
      // Checked Selection
      checked={selectedData}
      setChecked={setSelectedItems}
      // Row Selection
      selectedRow={selectedRow}
      setSelectedRow={setSelectedRow}
      // Modal Control
      setModal={setModal}
      modal={modal}
    />
  );
}
