import React, { useState } from "react";

// Components
import PostContractSelectListTable from "./PostContractSelectListTable";
import { authHeader } from "../../_helpers/auth-header";
import { Notify } from "../post-contract-commentary/Clarifications";
// Styles
export default function PostContractCriticalSelection(props) {
  const { setShow } = props;
  const { data } = props;
  const { postContract } = props;
  const { url } = props;
  const [error, setError] = useState({});

  const [selectedItems, setSelectedItems] = useState(
    data.filter((r) => r.is_critical)
  );

  return (
    <div>
      <PostContractSelectListTable
        data={data}
        setShow={setShow}
        selectedData={selectedItems}
        setSelectedItems={setSelectedItems}
      />
      <div className="general-row-container">
        <Notify error={error} setError={setError} />
      </div>
      <div className="general-row-container-double">
        <div
          className="create-project-save-cost-managers-button"
          onClick={() => {
            // IDs of the selected variations will be the critical ones
            const isCriticalIDs = selectedItems.map((e) => e.id);

            // The difference between the total set and the critical set is the non-critical set
            const notCriticalIDs = data
              .map((e) => e.id)
              .filter((e) => !isCriticalIDs.some((v) => e === v));

            const dataObject = {
              postContract: postContract,
              notCriticalIDs: notCriticalIDs,
              isCriticalIDs: isCriticalIDs,
              url: url,
              setError: setError,
              setShow: setShow,
            };

            onSetCriticalVariations(dataObject);
          }}
        >
          Save
        </div>
      </div>
    </div>
  );
}

async function onSetCriticalVariations(data) {
  const url = data.url;
  const field = JSON.stringify({
    isCriticalIDs: data.isCriticalIDs,
    notCriticalIDs: data.notCriticalIDs,
    projectID: data.postContract.projectID,
  });

  const formData = new FormData();
  formData.append("field", field);

  const config = {
    method: "PUT",
    body: formData,
    headers: authHeader({ authJson: false, authForm: true, guestJson: false }),
  };

  data.setError({
    text: "Saving...",
    type: "feedback-success",
  });
  let response = await fetch(url, config);
  if (response.ok) {
    data.setError({
      text: "Saved Successfully",
      type: "feedback-success",
    });
    // Response OK
    data.postContract.set(
      await data.postContract.get(data.postContract.projectID)
    );
    data.setShow(false);
  } else {
    console.log("Critical records save Error");
  }
}
