import isEmpty from "../../validation/is-empty";
import { tranStr } from "../../utils/translation";

function generateCities(TPI) {
  let cities = [];

  if (isEmpty(TPI)) {
    cities.unshift({
      label: tranStr("Select Region & Country First"),
      value: "",
    });
    return cities;
  }

  if (isEmpty(TPI.locations)) {
    cities.unshift({
      label: tranStr("No TPI options for selected Region and Country"),
      value: "",
    });
    return cities;
  }

  TPI.locations.forEach((location) => {
    cities.push({
      label: location,
      value: location,
    });
  });
  cities.unshift({
    label: tranStr("Select TPI Location"),
    value: "",
  });

  return cities;
}

function regionList() {
  return [
    { label: tranStr("Select Region"), value: "" },
    {
      label: tranStr("Asia"),
      value: "Asia",
    },
    {
      label: tranStr("Australia"),
      value: "Australia",
    },
    {
      label: tranStr("Europe"),
      value: "Europe",
    },
    {
      label: tranStr("Latin America"),
      value: "Latin America",
    },
    {
      label: tranStr("Middle East"),
      value: "Middle East",
    },
    {
      label: tranStr("North America"),
      value: "North America",
    },
  ];
}

function qualityList() {
  return [
    { label: tranStr("Select Quality"), value: "" },
    {
      label: tranStr("Premium"),
      value: "Premium",
    },
    {
      label: tranStr("High"),
      value: "High",
    },
    {
      label: tranStr("Medium"),
      value: "Medium",
    },
    {
      label: tranStr("Basic"),
      value: "Basic",
    },
  ];
}

function phaseList() {
  return [
    {
      label: tranStr("Cost Planning"),
      value: "COST_PLANNING",
    },
    {
      label: tranStr("Post Contract"),
      value: "POST_CONTRACT",
    },
  ];
}

function currencyList() {
  return [
    { label: tranStr("Select Currency"), value: "" },
    {
      label: tranStr("Australian Dollar (AUD)"),
      value: "AUD",
    },
    {
      label: tranStr("Great British Pound (GBP)"),
      value: "GBP",
    },
    {
      label: tranStr("New Zealand Dollar (NZD)"),
      value: "NZD",
    },
    {
      label: tranStr("United States Dollar (USD)"),
      value: "USD",
    },
    {
      label: tranStr("Euro (EUR)"),
      value: "EUR",
    },
    {
      label: tranStr("Swiss Francs (CHF)"),
      value: "CHF",
    },
    {
      label: tranStr("Singapore Dollar (SGD)"),
      value: "SGD",
    },
    {
      label: tranStr("Hong Kong Dollar (HKD)"),
      value: "HKD",
    },
    {
      label: tranStr("Macua Pataca (MOP)"),
      value: "MOP",
    },
    {
      label: tranStr("Phillipines Peso (PHP)"),
      value: "PHP",
    },
    {
      label: tranStr("Malaysian Ringgit (MYR)"),
      value: "MYR",
    },
    {
      label: tranStr("UAE Arabian Dirham (AED)"),
      value: "AED",
    },
    {
      label: tranStr("Qatari Riyal (QAR)"),
      value: "QAR",
    },
    {
      label: tranStr("Saudi Arabrian Riyal (SAR)"),
      value: "SAR",
    },
    {
      label: tranStr("Omani Rial (OMR)"),
      value: "OMR",
    },
    {
      label: tranStr("Bahraini Dinar (BHD)"),
      value: "BHD",
    },
  ];
}

function procurementList() {
  return [
    { label: tranStr("Select Procurement"), value: "" },
    {
      label: tranStr("Lump Sum - Construct Only"),
      value: "Lump Sum - Construct Only",
    },
    {
      label: tranStr("Design and Construct"),
      value: "Design and Construct",
    },
    {
      label: tranStr("Construction Management"),
      value: "Construction Management",
    },
    {
      label: tranStr("Managing Contractor"),
      value: "Managing Contractor",
    },
    {
      label: tranStr("GMP"),
      value: "GMP",
    },
  ];
}

function typeList() {
  return [
    { label: tranStr("Select Type"), value: "" },
    {
      label: tranStr("New Build"),
      value: "New Build",
    },
    {
      label: tranStr("Fit-Out"),
      value: "Fit-Out",
    },
    {
      label: tranStr("Refurbishment"),
      value: "Refurbishment",
    },
    {
      label: tranStr("Extension"),
      value: "Extension",
    },
  ];
}

function measurementSystemList() {
  return [
    { label: tranStr("Select Type"), value: "" },
    {
      label: tranStr("Metric"),
      value: "METRIC",
    },
    {
      label: tranStr("Imperial"),
      value: "IMPERIAL",
    },
  ];
}
function sectorList() {
  return [
    { label: tranStr("Select Sector"), value: "" },
    {
      label: tranStr("Commercial"),
      value: "Commercial",
    },
    {
      label: tranStr("Commercial Retail"),
      value: "Commercial Retail",
    },
    {
      label: tranStr("Education, Scientific, Information"),
      value: "Education, Scientific, Information",
    },
    {
      label: tranStr("Health"),
      value: "Health",
    },
    {
      label: tranStr("Industrial"),
      value: "Industrial",
    },
    {
      label: tranStr("Protective Facilities"),
      value: "Protective Facilities",
    },
    {
      label: tranStr("Recreational"),
      value: "Recreational",
    },

    { label: tranStr("Recreational Sport"), value: "Recreational Sport" },
    {
      label: tranStr("Religious"),
      value: "Religious",
    },
    {
      label: tranStr("Residential"),
      value: "Residential",
    },
    {
      label: tranStr("Short Stay"),
      value: "Short Stay",
    },
    {
      label: tranStr("Transport"),
      value: "Transport",
    },
  ];
}

function subsectorList() {
  return [
    { label: tranStr("Select Subsector"), value: "", type: "" },
    {
      label: tranStr("Offices"),
      value: "Offices",
      type: "Commercial",
    },
    {
      label: tranStr("Workplace / Fit-Out"),
      value: "Workplace / Fit-Out",
      type: "Commercial",
    },
    {
      label: tranStr("Department Stores"),
      value: "Department Stores",
      type: "Commercial Retail",
    },
    {
      label: tranStr("Shoppping Centres"),
      value: "Shoppping Centres",
      type: "Commercial Retail",
    },
    {
      label: tranStr("Speciality Shops / Malls"),
      value: "Speciality Shops / Malls",
      type: "Commercial Retail",
    },
    {
      label: tranStr("College Buildings"),
      value: "College Buildings",
      type: "Education, Scientific, Information",
    },
    {
      label: tranStr("Libraries / Municipal Buildings"),
      value: "Libraries / Municipal Buildings",
      type: "Education, Scientific, Information",
    },
    {
      label: tranStr("Primary School Buildings"),
      value: "Primary School Buildings",
      type: "Education, Scientific, Information",
    },
    {
      label: tranStr("Research Institutions / Laboratories"),
      value: "Research Institutions / Laboratories",
      type: "Education, Scientific, Information",
    },
    {
      label: tranStr("Secondary School Buildings"),
      value: "Secondary School Buildings",
      type: "Education, Scientific, Information",
    },
    {
      label: tranStr("Student Accommodation"),
      value: "Student Accommodation",
      type: "Education, Scientific, Information",
    },
    {
      label: tranStr("University Buildings"),
      value: "University Buildings",
      type: "Education, Scientific, Information",
    },
    {
      label: tranStr("Aged Care Facilities"),
      value: "Aged Care Facilities",
      type: "Health",
    },
    {
      label: tranStr("Health Centres / Medical Suites"),
      value: "Health Centres / Medical Suites",
      type: "Health",
    },
    {
      label: tranStr("Hospitals"),
      value: "Hospitals",
      type: "Health",
    },
    {
      label: tranStr("Vetinary Hospitals and Animal Clinics"),
      value: "Vetinary Hospitals and Animal Clinics",
      type: "Health",
    },
    {
      label: tranStr("Data Centres"),
      value: "Data Centres",
      type: "Industrial",
    },
    {
      label: tranStr("Factories"),
      value: "Factories",
      type: "Industrial",
    },
    {
      label: tranStr("Logistics Park"),
      value: "Logistics Park",
      type: "Industrial",
    },
    {
      label: tranStr("Warehouse Units / Stores"),
      value: "Warehouse Units / Stores",
      type: "Industrial",
    },
    {
      label: tranStr("Fire Stations / Ambulance Stations / Police Stations"),
      value: "Fire Stations / Ambulance Stations / Police Stations",
      type: "Protective Facilities",
    },
    {
      label: tranStr("Law Courts"),
      value: "Law Courts",
      type: "Protective Facilities",
    },
    {
      label: tranStr("Prisons"),
      value: "Prisons",
      type: "Protective Facilities",
    },
    {
      label: tranStr(
        "Convention Centres / Cultural Centres / Auditoriums / Art Galleries"
      ),
      value:
        "Convention Centres / Cultural Centres / Auditoriums / Art Galleries",
      type: "Recreational",
    },
    {
      label: tranStr("Performing Arts Entities / Theatres / Cinemas"),
      value: "Performing Arts Entities / Theatres / Cinemas",
      type: "Recreational",
    },
    {
      label: tranStr("Restaurants / Pubs / Clubs"),
      value: "Restaurants / Pubs / Clubs",
      type: "Recreational",
    },
    {
      label: tranStr("Theme Parks and Resorts"),
      value: "Theme Parks and Resorts",
      type: "Recreational",
    },
    {
      label: tranStr("Sports Centre"),
      value: "Sports Centre",
      type: "Recreational Sport",
    },
    {
      label: tranStr("Sports Fields"),
      value: "Sports Fields",
      type: "Recreational Sport",
    },
    {
      label: tranStr("Stadia"),
      value: "Stadia",
      type: "Recreational Sport",
    },
    {
      label: tranStr("Swimming Pools"),
      value: "Swimming Pools",
      type: "Recreational Sport",
    },
    {
      label: tranStr("Places of Worship"),
      value: "Places of Worship",
      type: "Religious",
    },
    {
      label: tranStr("Places of Worship - Other"),
      value: "Places of Worship - Other",
      type: "Religious",
    },
    {
      label: tranStr("Apartments"),
      value: "Apartments",
      type: "Residential",
    },
    {
      label: tranStr("Single and Double Storey Dwellings"),
      value: "Single and Double Storey Dwellings",
      type: "Residential",
    },
    {
      label: tranStr("Townhouses"),
      value: "Townhouses",
      type: "Residential",
    },
    {
      label: tranStr("Hotels - 3-Star"),
      value: "Hotels - 3-Star",
      type: "Short Stay",
    },
    {
      label: tranStr("Hotels - 4-Star"),
      value: "Hotels - 4-Star",
      type: "Short Stay",
    },
    {
      label: tranStr("Hotels - 5+ Star"),
      value: "Hotels - 5+ Star",
      type: "Short Stay",
    },
    {
      label: tranStr("Aviation - Airport Terminal"),
      value: "Aviation - Airport Terminal",
      type: "Transport",
    },
    {
      label: tranStr("Car Parking"),
      value: "Car Parking",
      type: "Transport",
    },
    {
      label: tranStr("Rail - Train Stations"),
      value: "Rail - Train Stations",
      type: "Transport",
    },
  ];
}

export {
  phaseList,
  generateCities,
  regionList,
  qualityList,
  currencyList,
  procurementList,
  typeList,
  measurementSystemList,
  sectorList,
  subsectorList,
};
