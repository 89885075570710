import { useState, useEffect } from "react";
import { authHeader } from "../../_helpers/auth-header";
import { hasRoles } from "../../utils/roles";
import { bmAPIs } from "../api-endpoints/BenchmarkingEndpoints";
import { AutoCloseModal } from "../../components/modal/Modal";

// Benchmarking State
export function useBenchmarking(user,company_id) {
  const [benchmarkingLoading, setLoading] = useState(true);
  const [benchmarking, setBenchmarking] = useState([]);
  useEffect(() => {
    if (hasRoles(user.roles, ["CostManager"])) {
      setLoading(true);
      getBenchmarking(company_id)
        .then((benchmarkingRes) => {
          setBenchmarking(benchmarkingRes);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }, [user.roles,company_id]);

  return {
    benchmarkingLoading,
    benchmarking,
    error: null,
    setBenchmarking,
  };
}

// Get Benchmarks
export async function getBenchmarking(company_id) {
  let url = bmAPIs().read_benchmarks_core+"?companyId=";
  if(company_id!==undefined)
    {
      url+=company_id;
    }
  const config = {
    method: "GET",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };
  let erMessage = "Get Benchmarking Error";
  let response = await fetch(url, config);
  if (response.ok) {
    response = await response.json();
    response = response.data;
  } else {
    response = [];
    console.log(erMessage);
  }

  return response;
}

// Delete Benchmarks
export async function deleteBenchmarking(selection, setBenchmarking) {
  const url = bmAPIs().delete_benchmarks;
  const payload = {
    BenchmarkIds: selection,
  };

  const config = {
    method: "DELETE",
    body: JSON.stringify(payload),
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };
  let erMessage = "Delete benchamrking Error";
  let response = await fetch(url, config);
  if (response.ok) {
    // Response OK
  } else {
    // Response Not OK
    console.log(erMessage);
  }

  setBenchmarking(await getBenchmarking());
}

export async function saveTPILocations(
  selection,
  tpi_city,
  setBenchmarking,
  setError
) {
  if (tpi_city === "") {
    return setError({
      text: "Please Select TPI",
      type: "feedback-error",
    });
  }

  if (selection.length < 1) {
    return setError({
      text: "Please Select Benchmarks",
      type: "feedback-error",
    });
  }

  setError({
    text: "Saving...",
    type: "feedback-success",
  });

  const url = bmAPIs().update_benchmarks_tpi_city;
  const payload = {
    BenchmarkIds: selection.map((x) => x.id),
    TpiCity: tpi_city,
  };

  const config = {
    method: "POST",
    body: JSON.stringify(payload),
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };
  let erMessage = "Save TPI Location Error";
  let response = await fetch(url, config);
  if (response.ok) {
    // Response OK
    setError({
      text: "Saved Successfully",
      type: "feedback-success",
    });
  } else {
    // Response Not OK
    console.log(erMessage);
    setError({
      text: "Save Failed",
      type: "feedback-error",
    });
  }

  setBenchmarking(await getBenchmarking());
}


export async function saveSensitivityInformationFlag(
 data
) {

  if (data.sensitivityFlag === "") {
    return data.setError({
      text: "Please Select Sensitivity Flag",
      type: "feedback-error",
    });
  }

  if (data.selection.length < 1) {
    return data.setError({
      text: "Please Select Benchmarks",
      type: "feedback-error",
    });
  }

  data.setError({
    text: "Saving...",
    type: "feedback-success",
  });

  const url = bmAPIs().update_benchmarks_info_sensitivity_level;
  const payload = {
    BenchmarkIds: data.selection.map((x) => x),
    InformationSensitivityLevel: data.sensitivityFlag,
  };

  const config = {
    method: "POST",
    body: JSON.stringify(payload),
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };
  let erMessage = "Save Sensitivity Level Flag Error";
  let response = await fetch(url, config);
  if (response.ok) {
    // Response OK
    
    data.setError({
      text: "Saved Successfully",
      type: "feedback-success",
    });
    AutoCloseModal(data.setModal);
  } else {
    // Response Not OK
    console.log(erMessage);
    data.setError({
      text: "Save Failed",
      type: "feedback-error",
    });
  }
  data.setBenchmarking(await getBenchmarking());
}