import xlsx from "@e965/xlsx";

const spreadsheets = (name, array) => {
  let worksheet = xlsx.utils.aoa_to_sheet(array);
  let colWidths = [];

  for (let [i] of array[0].entries()) {
    let width = 0;
    for (let [j] of array.entries()) {
      if (array[j][i]) {
        let char = array[j][i].toString().length;
        if (char > width) {
          width = char;
        }
      }
    }
    colWidths.push({ wch: width });
  }
  worksheet["!cols"] = colWidths;
  let workbook = xlsx.utils.book_new();
  xlsx.utils.book_append_sheet(workbook, worksheet);
  xlsx.writeFile(workbook, `${name}.xlsx`);
};

export function jsonToSpreadsheet(name, array, columnNames) {
  let workbook = xlsx.utils.book_new();
  const occurrences = {};
  const maxWorksheetNameLength = 25;

  array.forEach((element) => {
    let worksheet = xlsx.utils.json_to_sheet([element]);
    let worksheetName = element.project_name ?? "worksheet";
    worksheetName = worksheetName.substring(0, maxWorksheetNameLength);

    if (columnNames && columnNames.length > 0) {
      for (let i = 0; i < columnNames.length; i++) {
        const cellAddress = xlsx.utils.encode_cell({ r: 0, c: i });
        worksheet[cellAddress].v = columnNames[i];
      }
    }
    // resolve worksheet name conflict.
    occurrences[worksheetName] = (occurrences[worksheetName] || 0) + 1;
    if (occurrences[worksheetName] > 1) {
      worksheetName = `${worksheetName} (${occurrences[worksheetName]})`;
    }
    xlsx.utils.book_append_sheet(workbook, worksheet, worksheetName);
  });
  xlsx.writeFile(workbook, `${name}.xlsx`);
}

export default spreadsheets;
