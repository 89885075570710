import isEmpty from "../../validation/is-empty";
import { commaToFixed } from "../../helpers/common.functions";
import PropTypes from "prop-types";

import "./TableBodyCells.css";

TableCell.propTypes = {
  cell: PropTypes.object,
};

export function TableCell(props) {
  const { cell } = props;
  const { type } = cell;

  if (type === "IMAGE") {
    return <ImageCell {...props} />;
  }
  if (type === "NUMBER") {
    return <NumberCell {...props} />;
  }
  if (type === "DESCRIPTION") {
    return <DescriptionCell {...props} />;
  }
  if (type === "STATUS") {
    return <StatusCell {...props} />;
  }
  if (type === "CURRENCY") {
    return <CurrencyCell {...props} />;
  }
  if (type === "PERCENT") {
    return <PercentCell {...props} />;
  }
  if (type === "DATE") {
    return <DateCell {...props} />;
  }
  if (type === "QUANTITY") {
    return <QuantityCell {...props} />;
  }
  if (type === "TEXT") {
    return <TextCell {...props} />;
  }
  if (
    type === "LINK" ||
    type === "COMMENTS" ||
    type === "REASONS" ||
    type === "CRITICAL"
  ) {
    return <IconCell {...props} />;
  }
  if (type === "CHECK") {
    return <CheckCell {...props} />;
  }
}

ImageCell.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  cell: PropTypes.object,
  setModal: PropTypes.func,
  customComponent: PropTypes.element,
};

export function ImageCell(props) {
  const { value } = props;
  const { id } = props;
  const { cell } = props;
  const { width } = cell;
  const { setModal } = props;
  const { customComponent } = cell;

  if (isEmpty(value)) {
    return <div className="pct-percent" style={{ width: width }}></div>;
  }

  if (customComponent) {
    return (
      <button
        id={id}
        className="pct-percent"
        style={{ width: width }}
        onClick={() => setModal()}
      >
        {customComponent(value)}
      </button>
    );
  } else {
    return <div></div>;
  }
}

DescriptionCell.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  cell: PropTypes.object,
  setModal: PropTypes.func,
};

export function DescriptionCell(props) {
  const { id } = props;
  const { value } = props;
  const { cell } = props;
  const { width } = cell;
  const { setModal } = props;

  if (isEmpty(value)) {
    return <div className="pct-description" style={{ width: width }}></div>;
  }

  return (
    <button
      id={id}
      className="pct-description"
      style={{ width: width }}
      onClick={() => setModal()}
    >
      {value}
    </button>
  );
}

TextCell.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  cell: PropTypes.object,
  setModal: PropTypes.func,
};

export function TextCell(props) {
  const { id } = props;

  const { value } = props;
  const { cell } = props;
  const { width } = cell;
  const { setModal } = props;

  if (isEmpty(value)) {
    return <div className="pct-text" style={{ width: width }}></div>;
  }

  return (
    <button
      id={id}
      className="pct-text"
      style={{ width: width }}
      onClick={() => setModal()}
    >
      <div>{value}</div>
    </button>
  );
}

StatusCell.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  cell: PropTypes.object,
  setModal: PropTypes.func,
};

export function StatusCell(props) {
  const { id } = props;
  const { value } = props;
  const { cell } = props;
  const { width } = cell;
  const { setModal } = props;

  if (isEmpty(value)) {
    return (
      <button
        id={id}
        className="pct-status"
        style={{ width: width }}
        onClick={() => setModal()}
      ></button>
    );
  }

  return (
    <button
      id={id}
      className="pct-status"
      style={{ width: width }}
      onClick={() => setModal()}
    >
      {value}
    </button>
  );
}

IconCell.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  cell: PropTypes.object,
  type: PropTypes.string,
  setModal: PropTypes.func,
};

export function IconCell(props) {
  const { id } = props;

  const { value } = props;
  const { cell } = props;
  const { width } = cell;
  const { type } = cell;
  const { setModal } = props;

  let icon;
  let classType = "pct-icon";

  if (type === "LINK") {
    classType = "pct-icon-blue";
    icon = <i className="fas fa-link"></i>;
  }

  if (type === "COMMENTS") {
    classType = "pct-icon-orange";
    icon = <i className="far fa-sticky-note"></i>;
  }

  if (type === "REASONS") {
    classType = "pct-icon-yellow";
    icon = <i className="fas fa-not-equal"></i>;
  }

  if (type === "CRITICAL") {
    classType = "pct-icon-red";
    icon = <i className="fas fa-exclamation-triangle"></i>;
  }

  if (isEmpty(value) || !value) {
    return (
      <button
        id={id}
        className="pct-icon"
        style={{ width: width }}
        onClick={() => setModal()}
      ></button>
    );
  }

  return (
    <button
      id={id}
      className="pct-icon"
      style={{ width: width }}
      onClick={() => setModal()}
    >
      <div className={classType}>{icon}</div>
    </button>
  );
}

CurrencyCell.propTypes = {
  id: PropTypes.string,

  value: PropTypes.string,
  cell: PropTypes.object,
  setModal: PropTypes.func,
};

export function CurrencyCell(props) {
  const { id } = props;

  const { value } = props;
  const { cell } = props;
  const { width } = cell;
  const { setModal } = props;

  if (isEmpty(value) || isNaN(value)) {
    return (
      <button
        id={id}
        className="pct-value"
        style={{ width: width }}
        onClick={() => setModal()}
      ></button>
    );
  }

  return (
    <button
      id={id}
      className="pct-value"
      style={{ width: width }}
      onClick={() => setModal()}
    >
      <div></div>
      <div>{commaToFixed(value)}</div>
    </button>
  );
}

QuantityCell.propTypes = {
  id: PropTypes.string,

  value: PropTypes.string,
  cell: PropTypes.object,
  setModal: PropTypes.func,
};

export function QuantityCell(props) {
  const { id } = props;

  const { value } = props;
  const { cell } = props;
  const { width } = cell;
  const { setModal } = props;

  return (
    <button
      id={id}
      className="pct-quantity"
      style={{ width: width }}
      onClick={() => setModal()}
    >
      <div>{commaToFixed(value)}</div>
    </button>
  );
}

PercentCell.propTypes = {
  id: PropTypes.string,

  value: PropTypes.string,
  cell: PropTypes.object,
  setModal: PropTypes.func,
};

export function PercentCell(props) {
  const { id } = props;

  const { value } = props;
  const { cell } = props;
  const { width } = cell;
  const { setModal } = props;

  if (isEmpty(value) || isNaN(value)) {
    return (
      <button
        id={id}
        className="pct-percent"
        style={{ width: width }}
        onClick={() => setModal()}
      ></button>
    );
  }

  return (
    <button
      id={id}
      className="pct-percent"
      style={{ width: width }}
      onClick={() => setModal()}
    >
      <div>{commaToFixed(value)}</div>
      <div>%</div>
    </button>
  );
}

NumberCell.propTypes = {
  id: PropTypes.string,

  value: PropTypes.string,
  cell: PropTypes.object,
  setModal: PropTypes.func,
};

export function NumberCell(props) {
  const { id } = props;

  const { value } = props;
  const { cell } = props;
  const { width } = cell;
  const { setModal } = props;

  return (
    <button
      id={id}
      className="pct-number"
      style={{ width: width }}
      onClick={() => setModal()}
    >
      <div>{value}</div>
    </button>
  );
}

DateCell.propTypes = {
  id: PropTypes.string,

  value: PropTypes.string,
  cell: PropTypes.object,
  setModal: PropTypes.func,
};

export function DateCell(props) {
  const { id } = props;

  const { value } = props;
  const { cell } = props;
  const { width } = cell;
  const { setModal } = props;

  // Some dates may be a timestampe

  let newDate = {};

  if (!isNaN(value)) {
    newDate = new Date(parseInt(value));
  } else {
    newDate = new Date(value);
  }

  let date = {
    day: newDate.toLocaleString("default", {
      day: "numeric",
    }),
    month: newDate.toLocaleString("default", {
      month: "short",
    }),
    year: newDate.toLocaleString("default", {
      year: "numeric",
    }),
  };

  if (value === 0 || isEmpty(value)) {
    return (
      <button
        id={id}
        className="pct-percent"
        style={{ width: width }}
        onClick={() => setModal()}
      ></button>
    );
  }

  return (
    <button
      id={id}
      className="pct-percent"
      style={{ width: width }}
      onClick={() => setModal()}
    >
      <div>{date.day + " " + date.month + " " + date.year}</div>
    </button>
  );
}

CheckCell.propTypes = {
  id: PropTypes.string,

  value: PropTypes.string,
  cell: PropTypes.object,
  checked: PropTypes.array,
  setChecked: PropTypes.func,
  tableArray: PropTypes.array,
};

export function CheckCell(props) {
  const { id } = props;

  const { value } = props;
  const { cell } = props;
  const { width } = cell;
  const { checked, setChecked } = props;
  const { tableArray } = props;

  if (isEmpty(value)) {
    return <div className="pct-checkmark" style={{ width: width }}></div>;
  }

  return (
    <div className="pct-checkmark" style={{ width: width }}>
      <input
        id={id}
        className="pct-checkmark"
        type="checkbox"
        checked={checked.some((e) => e[cell.key] === value)}
        onChange={(e) => {
          const isChecked = e.target.checked;

          const object = tableArray.find((c) => c[cell.key] === value);

          // If checking this option
          if (isChecked) {
            // Set the locations to current selection + the selected
            setChecked((selectedItems) => {
              return [...selectedItems, object];
            });
          }

          // If unchecking the option
          if (!isChecked) {
            // Set the locations to the current selection
            setChecked((selectedItems) =>
              // Filtering out the seleted
              selectedItems.filter((x) => x[cell.key] !== value)
            );
          }
        }}
      />
    </div>
  );
}
